import React, { useState,useEffect } from "react"
import {
  Container,
  Row,
  Col,
  //Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  //CardSubtitle,
  Button,Modal,
  UncontrolledTooltip,
  //FormFeedback,
} from "reactstrap"
// import ToolTipData from './Settings.json'
//import Select from "react-select"
//import { Link } from "react-router-dom"
const { default: axios } = require("axios");
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm } from "react-hook-form";
import api from '../../helpers/api_helper';
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { telegramMsg,telegramIDMsg,telegramChatIDMsg,MinimumTakeProfit,TaillingTakeProfit,MidPrize } from "../../helpers/help-msg";
import { trendFormValidation } from "../../services/common.service";
import parse from 'html-react-parser';
import { Field } from "formik";
import { min, spread } from "lodash";
const Settings = () => {
  firebase.auth();
   //tooltip
   const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
   const [ToolTipData, setToolTipData] = useState([]);
   useEffect(()=>{
     try{
       const gettoottipData = async () => {
       
           const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
           // console.log(response)
             setToolTipData(JSON.parse(JSON.stringify(response.data)))
         
         }
 
         gettoottipData()
       }catch(error){
           console.log(error)
       }
 
   },[apiUrl])
   //tooltip
  const { register, handleSubmit, setValue, reset, control, formState: { errors } } = useForm();
  const { register : register2, handleSubmit : handleSubmit2, setValue:setValue2, control: control2, formState: { errors:errors2 } } = useForm();
  const { register : register3, handleSubmit : handleSubmit3, setValue:setValue3, control: control3, formState: { errors:errors3 } } = useForm();
  const { register : register4, handleSubmit : handleSubmit4, setValue:setValue4, control: control4, formState: { errors:errors4 } } = useForm();
  const { register : register5, handleSubmit : handleSubmit5, setValue:setValue5, control: control5, formState: { errors:errors5 } } = useForm();
  const { register : register6, handleSubmit : handleSubmit6, setValue:setValue6, control: control6, formState: { errors:errors6 } } = useForm();
  const [activeTab, setactiveTab] = useState("4")
  //const [selectedGroup, setselectedGroup] = useState(null);
  const [allowAdminStatus, setAllowAdminStatus] = useState(false);
  const [admin_diagonal_qty, setAdminDiagonalQty] = useState("");
  const [form1_loader, setForm1Loader] = useState({ loading: false });
  const [form2_loader, setForm2Loader] = useState({ loading: false });
  const [form3_loader, setForm3Loader] = useState({ loading: false });
  const [form4_loader, setForm4Loader] = useState({ loading: false });
  const [form5_loader, setForm5Loader] = useState({ loading: false });
  const [form6_loader, setForm6Loader] = useState({ loading: false });
  const [form7_loader, setForm7Loader] = useState({ loading: false });
  const [form8_loader, setForm8Loader] = useState({ loading: false });
  const [form9_loader, setForm9Loader] = useState({ loading: false });
  const [form10_loader, setForm10Loader] = useState({ loading: false });
  const firestoreDb = firebase.firestore();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  const [admin_strangle_qty, setAdminStrangleQty] = useState("");
  const [telegramStatus, setTelegramStatus] = useState(false);
  const [automaticStranglebot, setAutomaticStranglebot] = useState(false);	  
  const [CCXTspottrade,setCCXTspottrade]=useState(false)
  const [stranglePricePercentage,setStranglePricePercentage] = useState(0);
  const [marketRangePercentage,setMarketRangePercentage] = useState(0);
  const [minimumPremium,setMinimumPremium]=useState(0)
  const [spreadPercentage,setSpreadPercentage]=useState(100)
  const [stranglebot, setStranglebot] = useState(false);	
  const [diagonalbot, setDiagonalbot] = useState(false);
  const [straddlebot, setStraddlebot] = useState(false);
  const [indexPercentage, setIndexPercentage] = useState(0);
  const [triggerPercentage, setTriggerPercentage] = useState(0);
  const [stopLossPercentage, setStopLossPercentage] = useState(0);
  const [combinedSl, setCombinedSl]=useState(0);
  const [combinedTrailProfitPercentage, setCombinedTrailProfitPercentage]=useState(0);
  const [combinedTrailSl, setCombinedTrailSl]=useState(0);
  const [combinedProfitPercentage, setCombinedProfitPercentage]=useState(0);
  const [stopLossIndexPercentage, setStopLossIndexPercentage] = useState(0);
  const [takeProfitPercentage, setTakeProfitPercentage] = useState(0);
  const [rollPercentage, setRollPercentage] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [qtyTime,setQtyTime] = useState("");
  const [autoMarketFill, setAutoMarketFill] = useState(false);	
  const [universalStop, setUniversalStop] = useState(false);
  const [indexDistance,setIndexDistance] = useState(0);
  const [rollStatus, setRollStatus] = useState(false);
  const [rollTime,setRollTime] = useState("");
  const [expiryTimeInterval,setExpiryTimeInterval] = useState("");
  const [adminOrderStatus, setAdminOrderStatus] = useState(false);
  const [adminBotMsg, setAdminBotMsg] = useState(false);  
  const [userProfitMsgStatus,setUserProfitMsgStatus] = useState(false); 
  const [visibleTailling, setVisibleTailling] = useState(false);
  const [visibleMin, setVisibleMin] = useState(false);
  const [MintakeProfitMsg,setMintakeProfitMsg] = useState();
  const [TaillingTakeProfitMsg,setTaillingTakeProfitMsg] = useState();
  const [userProfitMsgTime, setUserProfitMsgTime] = useState("5m");
  const [visible, setVisible] = useState(false);
  const [visibleID,setVisibleID] = useState(false);
  const [teleMsg, setTeleMsg] = useState();
  const [teleIDMsg,setTeleIDMsg] = useState();    
  const [trendSide,setTrendSide]= useState('sell');
  const [visibleMidPrize, setVisibleMidPrize] = useState(false);
  const [MidPrizeMsg,setMidPrizeMsg] = useState();
  const [strangleMinTakeProfit, setStrangleMinTakeProfit] = useState("");
  const [strangleTrailTakeProfit, setStrangleTrailTakeProfit] = useState("");
  const [SlTriggerStrangles, setSlTriggerStrangles]=useState(true);
  const [SlTriggerStraddles, setSlTriggerStraddles]=useState(true);
  const [TraillingStrangles,setTraillingStrangles]=useState(false);
  const [TraillingStraddles,setTraillingStraddles]=useState(false);
  const [strangleswait_time_reentry, setstrangleswait_time_reentry] = useState(0);
  const [straddleswait_time_reentry, setstraddleswait_time_reentry] = useState(0);
  const [reentry_enabled, setreentry_enabled] = useState(false);
  const [straddlereentry_enabled, setstraddlereentry_enabled] = useState(false);
  const [serverIP, setServerIP] = useState("");
  const [trendSettings, setTrendSettings]=useState({
    trend_method:"sma",
    short_term:10,
    long_term:100,
    time_frame:"15m",
    ema_short_term:10,
    ema_long_term:100,
    ema_time_frame:"15m",
    stop_loss_percentage:30,
    min_take_profit_percentage:10,
    trailing_take_profit_percentage:5,
    waitTimeAfterStopLoss_clock:"0:30:0",
    waitTimeForReEntry_clock:"0:20:0",
    waitTimeForPrice_clock:"0:05:0",
    waitTimeAfterStopLoss:1800,
    waitTimeForReEntry:1200,
    waitTimeForPrice:300,
    close_old_positions:true,
    index_distance:3,
    index_distance_type:'percentage',
    trend_side : 'sell',
    trend_day:'D1',
    strike_count:0,
    mid_price_percentage:0

})
const [time_to_close_orders_before_expiry, settime_to_close_orders_before_expiry] = useState(0);
const [time_to_close_orders_before_expiry_straddles, settime_to_close_orders_before_expiry_straddles] = useState(0);
  
const handleDH = (e,strategy = '') => {
  var time = e[0]
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var H_M = toSeconds(hours, minutes, 0);
  
  if (strategy == 'strangleswait_time_reentry'){
    setstrangleswait_time_reentry(H_M)
  }else if (strategy == 'straddleswait_time_reentry'){
    setstraddleswait_time_reentry(H_M)
  }else if (strategy == 'time_to_close_orders_before_expiry'){
    settime_to_close_orders_before_expiry(H_M)
  }else if (strategy == 'time_to_close_orders_before_expiry_straddles'){
    settime_to_close_orders_before_expiry_straddles(H_M)
  }
}
  const[trendViewURL,setTrendViewURL]=useState("/trendview");
  const [visibleChatID,setVisibleChatID] = useState(false);
  const [teleChatIDMsg, setTeleChatIDMsg] = useState();
  
  //Straddle Bot setup / settings
  const [straddleBotSettings, setStraddleBotSettings] = useState({
    stop_loss_percentage: 30,
    stop_loss_index_percentage: 1,
    //trigger_percentage: 20,
    minimum_take_profit_percentage: 10,
    trailing_take_profit_percentage: 5,
    quantity: 1,
    automatic_straddle_bot: 0,
    autobot_price_difference_percentage: 10,
    autobot_expiry_time_interval_clock: "0:30:0",
    minimum_premium:0,
    spread_percentage:100,
    combined_sl:false,
    Sl_trigger_take_profit:true,
    combined_sl_percentage:0,
    combined_trailing_take_profit_percentage:0,
    combined_trailing_stop_price_percentage:0,
  });

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const handleTrendSettingsChange = (e) => {
    setTrendSettings({
      ...trendSettings,
      [e.target.name]: e.target.value
  })}
   const handleTrendSettingsSliderChange=(value,field) => {
    setTrendSettings({
      ...trendSettings,
      [field]: value
    })}
    // const handleTrendSettingsTimeChanges = (e,field) => {
    //   setTrendSettings({
    //     ...trendSettings,
    //     [field]: new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds(),
    // })
    // }
    const handleTrendSettingsTimeChange=(e,field) => {
    const order_get_hour = new Date(e).getHours()
    const order_get_minutes = new Date(e).getMinutes();
    let order_total_secs;
    if(order_get_hour == '0')
    {
      order_total_secs = Number(order_get_minutes) * 60;  
    }
    else
    {
      const total_hours = Number(order_get_hour) * 3600;
      const total_minutes = Number(order_get_minutes) * 60;
      order_total_secs = total_hours + total_minutes;
    }

      setTrendSettings({
        ...trendSettings,
        [field+'_clock']: new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds(),
        [field]:order_total_secs
    })
  
  }
  const [CCXTTrendTime,setCCXTTrendTime]=useState(300);
  const getCCXTSettings = async () => {
    try {
      await api({ contentType: true, auth: true }).get('/abotex/delta/get-ccxt-settings').then((res) => {
        setCCXTTrendTime(res.data.response.ccxt_settings.trend_time)
        setCCXTspottrade(res.data.response.ccxt_settings.trade_enabled)
      }).catch(err => {
        throw err
      })
    }
    catch(err)
    {
      toast.error(err.response.data.message);
    }
  }
  const CCXTSettings = async () => {
    try {
      setForm9Loader({
        loading: true,  
      });
      const bodyParams = {
        uid: uid,
        trend_time:CCXTTrendTime,
        trade_enabled:CCXTspottrade
      }
      await api({ contentType: true, auth: true }).post('/abotex/delta/update-ccxt-settings', bodyParams).then((res) => {
        setForm9Loader({
          loading: false,
        });
        toast.success("CCXT settings updated");
      }).catch(err => {
        throw err
      })
    }
    catch(err)
    {
      setForm9Loader({
        loading: false,
      });
      toast.error(err.response.data.message);
    }
  }
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);
  
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return hours + ":" + minutes ;
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  const handleCCXTTime = (e) => {
    var time = e[0]
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    setCCXTTrendTime(H_M)
  }
  const keySettingSubmit = async(data)=>{
    try
    {
      setForm1Loader({
        loading: true,
      });
      
      const bodyParams = {
        appKey : data.api_key,
        appSecret : data.api_secret
      }               

      await api({ contentType: true, auth: true }).post('/abotex/delta/update-apikey-settings', bodyParams).then((res) => {
        setForm1Loader({
          loading: false,
        });
        // dispatch(updateApiKeys(bodyParams));
        toast.success("ApiKey settings updated");
      }).catch(err => {      
        throw err
      })    
    }
    catch(err)
    {      
      setForm1Loader({          
        loading: false,
      });
      toast.error(err?.response?.data?.message || "Something went wrong.");
    }
  }  

  const botSettingSubmit = async (data) => {
    try
    {        
      setForm2Loader({
        loading: true,
      });
      // if(triggerPercentage >= stopLossPercentage)
      // {
      //   throw toast.error("SL % must more than Trigger %");
      // }        
      // qty time selection changes in secs
      var qty_timeArr = qtyTime.split(":");
      const qty_get_hour = qty_timeArr[0];
      const qty_get_minutes = qty_timeArr[1];
      let qty_total_secs;
      if(qty_get_hour == '0')
      {
        qty_total_secs = Number(qty_get_minutes) * 60;  
      }
      else
      {
        const total_hours = Number(qty_get_hour) * 3600;
        const total_minutes = Number(qty_get_minutes) * 60;
        qty_total_secs = total_hours + total_minutes;
      }            
      // roll time selection changes in secs
      var roll_timeArr = rollTime.split(":");
      const roll_get_hour = roll_timeArr[0];
      const roll_get_minutes = roll_timeArr[1];
      let roll_total_secs;
      if(roll_get_hour == '0')
      {
        roll_total_secs = Number(roll_get_minutes) * 60;

      }
      else
      {
        const roll_total_hours = Number(roll_get_hour) * 3600;
        const roll_total_minutes = Number(roll_get_minutes) * 60;
        roll_total_secs = roll_total_hours + roll_total_minutes;
      }      
      const bodyParams = {
        uid: uid,
        index_percentage: indexPercentage,
        // trigger_percentage: triggerPercentage,
        stop_loss_percentage: stopLossPercentage,
        stop_loss_index_percentage: stopLossIndexPercentage,  
        take_profit_percentage:takeProfitPercentage,
        roll_percentage:!reentry_enabled && rollStatus ? rollPercentage : 0,
        quantity:quantity,
        index_distance:!reentry_enabled && rollStatus ? data.index_distance : 0,
        waitTimeForFillAllQty:qty_total_secs,
        Qty_time_clock:qtyTime?qtyTime:"0:30:0",
        waitTimeForRoll:!reentry_enabled && rollStatus ? roll_total_secs : 0,
        Roll_time_clock:!reentry_enabled &&  rollStatus ? rollTime : "0:0:0",
        autoMarketFill: autoMarketFill == true ? 1 : 0,
        roll_status: !reentry_enabled && rollStatus == true ? 1 : 0,
        universal_stop: universalStop == true ? 1 : 0,
        enable_strangles : stranglebot == true ? 1 : 0,
        trailing_take_profit_percentage:strangleTrailTakeProfit,
        min_take_profit_percentage:strangleMinTakeProfit,
        combined_sl_percentage:combinedSl,
        combined_trailing_profit_percentage:combinedTrailProfitPercentage,
        combined_trailing_stop_price_percentage:combinedTrailSl,
        combined_take_profit_percentage:combinedProfitPercentage,
        Sl_trigger_take_profit:SlTriggerStrangles,
        combined_trailing_profit:TraillingStrangles == true ? 1 : 0,
        reentry_enabled:reentry_enabled == true ? 1 : 0,
        wait_time_reentry:strangleswait_time_reentry,
        time_to_close_orders_before_expiry:time_to_close_orders_before_expiry,
        
      };
      console.log(bodyParams,"nnnnnnnnnnnnnnnnnnnnnn");

      autobotSettingSubmit('',bodyParams)
    } catch (err) {
      if (err.response) {
        console.log(err.response , '$$$$$$$$$$$$$');
        if(err.response && err.response.data && err.response.data.message){
        toast.error(err.response.data.message);
        }
      }
      setForm2Loader({
        loading: false,
      });
  }  
    
  }

  const updateNewTrendSettings = async(form_data)=>{
    try
    {
      setForm6Loader({
        loading: true,
      });
      let bodyParams = trendSettings;  
      if(trendSettings.trend_side=="both" && Number(trendSettings.strike_count) < 2)
      {
        throw  toast.error('The strike count must be greater than or equal to 2 when you select the side as "Both"')
      }
      if(Number(trendSettings.total_quantity_limit) > 0 && Number(trendSettings.strike_count) > Number(trendSettings.total_quantity_limit))
      {
        throw  toast.error('Total quantity limit must enter more than or equal strike counts')
      }
    
      let errmsg=trendFormValidation(trendSettings)
      if(errmsg!=""){
        throw  toast.error(errmsg)
      }
      await api({ contentType: true, auth: true }).post('/abotex/delta/update-trend-settings', bodyParams).then((res) => {
        setForm6Loader({
          loading: false,
        });
        toast.success("Site settings updated");
      }).catch(err => {
        throw err
      })

      }
      catch(error)
      {
        setForm6Loader({
          loading: false,
        });
        error.response && error.response.data && error.response.data.message && toast.error(error.response.data.message);

      }
  }


  const autobotSettingSubmit = async(form_data,form_data2)=>{
    try{      
      setForm7Loader({
        loading: true,
      });
      if(automaticStranglebot && !marketRangePercentage)
      {
        setForm2Loader({          	
          loading: false,	
        });
        throw toast.error("Please fill all details");
      }
      if(expiryTimeInterval){
      var expiry_timeArr = expiryTimeInterval.split(":");
      const expiry_get_hour = expiry_timeArr[0];
      const expiry_get_minutes = expiry_timeArr[1];
      var expiry_total_secs;
      if(expiry_get_hour == '0')
      {
      expiry_total_secs = Number(expiry_get_minutes) * 60;  
      }
      else
      {
      const total_hours = Number(expiry_get_hour) * 3600;
      const total_minutes = Number(expiry_get_minutes) * 60;
      expiry_total_secs = total_hours + total_minutes;
      }   
    }
    
      const mainsettings = {
        automatic_strangle_bot:automaticStranglebot == true ? 1 : 0,
        market_range_percentage : marketRangePercentage,
        minimum_premium:minimumPremium,
        spread_percentage:spreadPercentage,
        strangle_price_difference_percentage : automaticStranglebot == true ?stranglePricePercentage:0,
        expiry_time_interval_clock:expiryTimeInterval ? expiryTimeInterval :"0:30:0",
        expiry_time_interval:expiry_total_secs ? expiry_total_secs : 1800
      }
      let bodyParams = {
        ...mainsettings,        
    };
    let api_count = 0;	
      await (api({ contentType: true, auth: true }).post('/abotex/delta/update-site-settings', bodyParams).then((res) => {	
        api_count = api_count + 1 	
      }).catch(err => {	
        throw err	
      }),	
      api({ contentType: true, auth: true }).post('/abotex/delta/update-bot-settings', form_data2).then((res) => {	
        setForm2Loader({          	
          loading: false,	
        });	
        api_count = api_count + 1 	
      }).catch(err => {	
        throw err	
      }) );	
      if (api_count == 2){	
        toast.success("Autobot & Bot settings updated");	
      }	
      // toast.success("Autobot settings updated");	
      // toast.success("Bot settings updated");	
    }	
    catch(err)	
    {	
      setForm2Loader({          	
        loading: false,	
      });
      if(err.response && err.response.data && err.response.data.message){
      toast.error(err.response.data.message);
      }	
      //err.response && toast.error(err.response.data.message);	
    }	
  }


  const diagonalSettingSubmit = async(form_data)=>{
    try{      
      setForm3Loader({
        loading: true,
      });
                      
      let bodyParams = {
        enable_diagonal : diagonalbot == true ? 1 : 0,
      };
 
      await api({ contentType: true, auth: true }).post('/abotex/delta/update-diagonals-settings', bodyParams).then((res) => {
        setForm3Loader({
          loading: false,
        });
        toast.success("Diagonals settings updated");
      }).catch(err => {
        throw err
      })

    }
    catch(err)
    {
      setForm3Loader({
        loading: false,
      });
      err.response &&  err.response.data &&  err.response.data.message && toast.error(err.response.data.message);
    }
  }    
    const telegramSettingSubmit = async(data)=>{
      try
      {        
        setForm4Loader({
          loading: true,
        });
        const bodyParams = {
          sell_order_msg: data.sell_order_msg,
          buy_order_msg: data.buy_order_msg?data.buy_order_msg:'',
          position_order_msg: data.position_order_msg,
          sl_order_msg: data.sl_order_msg,
          trigger_order_msg: data.trigger_order_msg,
          cancelled_order_msg: data.cancelled_order_msg,
          mismatch_order_msg : data.mismatch_order_msg,
          trend_signal_msg : data.trend_signal_msg,
          universel_order_msg: data.universel_order_msg,
          filled_order_msg: data.filled_order_msg,
          roll_order_msg: data.roll_order_msg,
          profit_order_msg: data.profit_order_msg,
          telegram_status: telegramStatus == true ? 1 : 0,
          admin_tele_token: data.admin_tele_token,
          admin_tele_chatid: data.admin_tele_chatid,
          allow_automatic_bot_msg:adminOrderStatus == true ? 1 : 0,
          allow_admin_bot_msg:adminBotMsg == true ? 1 : 0,
          user_profit_msg_status:userProfitMsgStatus == true ? 1 : 0,
          user_profit_msg_time : userProfitMsgStatus ? userProfitMsgTime : '5m',
          user_profit_msg_updated_time : ""
        }

      await api({ contentType: true, auth: true }).post('/abotex/delta/update-telegram-settings', bodyParams).then((res) => {
        setForm4Loader({
          loading: false,
        });
        toast.success("Telegram settings updated");     
        const disply_tele_token = data.admin_tele_token.slice(0,3)+'*****'+data.admin_tele_token.slice(-3);
          setValue4('admin_tele_token',disply_tele_token, { shouldValidate: false });   
      }).catch(err => {
        throw err
      })
  
      }
      catch(err)
      {
        setForm4Loader({
          loading: false,
        });      
        toast.error(err.response.data.message);
      }
    }
    // const trendSettingSubmit =async()=>{
    //   try{
    //   setForm6Loader({
    //     loading: true,
    //   });
    //   var wair_timeArr = waitTimeReentry.split(":");
    //   const order_get_hour = wair_timeArr[0];
    //   const order_get_minutes = wair_timeArr[1];
    //   let order_total_secs;
    //   if(order_get_hour == '0')
    //   {
    //     order_total_secs = Number(order_get_minutes) * 60;  
    //   }
    //   else
    //   {
    //     const total_hours = Number(order_get_hour) * 3600;
    //     const total_minutes = Number(order_get_minutes) * 60;
    //     order_total_secs = total_hours + total_minutes;
    //   }
    //   var sl_wair_timeArr = waitTimeSL.split(":");
    //   const sl_order_get_hour = sl_wair_timeArr[0];
    //   const sl_order_get_minutes =sl_wair_timeArr[1];
    //   let sl_order_total_secs;
    //   if(sl_order_get_hour == '0')
    //   {
    //     sl_order_total_secs = Number(sl_order_get_minutes) * 60;  
    //   }
    //   else
    //   {
    //     const sl_total_hours = Number(sl_order_get_hour) * 3600;
    //     const sl_total_minutes = Number(sl_order_get_minutes) * 60;
    //     sl_order_total_secs = sl_total_hours + sl_total_minutes;
    //   }

    //   const send_data = {
    //     stop_loss:trendStopLoss,
    //     waitTimeForReEntry:order_total_secs ?order_total_secs :'1800',
    //     waitTimeForReEntry_clock:waitTimeReentry?waitTimeReentry:"0:30:0",
    //     waitTimeAfterStopLoss:sl_order_total_secs ?sl_order_total_secs :'1800',
    //     waitTimeAfterStopLoss_clock:waitTimeSL?waitTimeSL:"0:30:0",
    //     index_distance:trendIndexPer?trendIndexPer:'0',
    //     index_distance_type:indexDistanceType?indexDistanceType:'percentage'
    //   }
    //   await api({ contentType: true, auth: true }).post('/abotex/delta/update-trend-following-settings', send_data).then((res) => {
    //     setForm6Loader({
    //       loading: false,
    //     });
    //     toast.success("Trend following settings updated");
    //   }).catch(err => {
    //     throw err
    //   })
    // } catch (err) {
    //   if (err.response) {
    //     toast.error(err.response.data.message);
    //   }
    //   setForm6Loader({
    //     loading: false,
    //   });
    // }
    // }
    const straddlesSettingSubmit= async(form_datas)=>{
      try{
        setForm5Loader({
          loading: true,
        });
          
          var order_timeArr = straddleBotSettings.autobot_expiry_time_interval_clock.split(":");
          const order_get_hour = order_timeArr[0];
          const order_get_minutes = order_timeArr[1];
          let order_total_secs;
          if(order_get_hour == '0')
          {
            order_total_secs = Number(order_get_minutes) * 60;  
          }
          else
          {
            const total_hours = Number(order_get_hour) * 3600;
            const total_minutes = Number(order_get_minutes) * 60;
            order_total_secs = total_hours + total_minutes;
          }
        //   if(straddleBotSettings.trigger_percentage >= straddleBotSettings.stop_loss_percentage)
        // {
        //   throw toast.error("SL % must more than Trigger %");
        // } 
          const send_data = {
            stop_loss_percentage: straddleBotSettings.stop_loss_percentage,
            // trigger_percentage: straddleBotSettings.trigger_percentage,
            stop_loss_index_percentage: straddleBotSettings.stop_loss_index_percentage,
            minimum_take_profit_percentage: straddleBotSettings.minimum_take_profit_percentage,
            trailing_take_profit_percentage: straddleBotSettings.trailing_take_profit_percentage,
            quantity: straddleBotSettings.quantity,
            automatic_straddle_bot: straddleBotSettings.automatic_straddle_bot ? 1 : 0,
            autobot_price_difference_percentage: straddleBotSettings.autobot_price_difference_percentage,
            autobot_expiry_time_interval:order_total_secs ?order_total_secs :'1800',
            autobot_expiry_time_interval_clock:straddleBotSettings.autobot_expiry_time_interval_clock,
            enable_straddle : straddlebot == true ? 1 : 0,
            minimum_premium:straddleBotSettings.minimum_premium,
            spread_percentage:straddleBotSettings.spread_percentage,
            combined_sl:straddleBotSettings.combined_sl?straddleBotSettings.combined_sl:false,
            combined_sl_percentage:straddleBotSettings.combined_sl_percentage,
            combined_trailing_take_profit_percentage:straddleBotSettings.combined_trailing_take_profit_percentage,
            combined_trailing_stop_price_percentage:straddleBotSettings.combined_trailing_stop_price_percentage,
            Sl_trigger_take_profit:SlTriggerStraddles,
            combined_trailing_profit:TraillingStraddles == true ? 1 : 0,
            reentry_enabled:straddlereentry_enabled == true ? 1 : 0,
            wait_time_reentry:straddleswait_time_reentry,
            time_to_close_orders_before_expiry:time_to_close_orders_before_expiry_straddles,
          }
        
          await api({ contentType: true, auth: true }).post('/abotex/delta/update-straddles-settings', send_data).then((res) => {
          setForm5Loader({
            loading: false,
          });
          toast.success("Straddles settings updated");
        }).catch(err => {
          throw err
        })
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setForm5Loader({
          loading: false,
        });
      }

    }

  const getConfig = async () => {
    const result = await firestoreDb.collection("users").doc(uid).collection("config").doc('data');
    let config_data = false;
    await result.get().then((doc) => {
      if (doc.exists) {					
        config_data = doc.data()
      }
    });
    if (config_data) {
      var key_secret = __KEY_SECRET__;
      var decry_appkey  = CryptoJS.AES.decrypt(config_data.appKey, key_secret);
      var decryptedAppkey = JSON.parse(decry_appkey.toString(CryptoJS.enc.Utf8));        
      var decry_appsecret  = CryptoJS.AES.decrypt(config_data.appSecret, key_secret);
      var decryptedAppsecret = JSON.parse(decry_appsecret.toString(CryptoJS.enc.Utf8));
      const disply_apikey = decryptedAppkey.slice(0,3)+'******'+decryptedAppkey.slice(-3);
      const display_api_secret = decryptedAppsecret.slice(0,3)+'******'+decryptedAppsecret.slice(-3);
      setValue('api_key',disply_apikey, { shouldValidate: false });
      setValue('api_secret',display_api_secret, { shouldValidate: false });
    }
  };
  const getTredviewURL=async()=>{
    
    let url="/trendview"
  
    setTrendViewURL(url)
    
  }
  const getServerIP=async()=>{
    try{
      await api({ contentType: true, auth: true }).get('/abotex/delta/get-server-ip').then((res) => {
        console.log(res.data.response.server_details.ip_address)
        setServerIP(res.data.response.server_details.ip_address)
      }).catch(err => {
        throw err
      })
    }catch(err){
      console.log(err)
    }
  }

  const saveIP=async()=>{
    try{
      //validate ip address before save
      if(serverIP){
        var ip = serverIP.split('.');
        if(ip.length == 4){
          for(var i = 0; i < ip.length; i++){
            if(ip[i] < 0 || ip[i] > 255){
              throw toast.error("Invalid IP address");
            }
          }
        }else{
          throw toast.error("Invalid IP address");
        }

      const bodyParams = {
        ip_address: serverIP
      }
      await api({ contentType: true, auth: true }).post('/abotex/delta/save-ip', bodyParams).then((res) => {
        toast.success("IP address saved");
      }).catch(err => {
        throw err
      })
    }else{
      throw toast.error("Please enter IP address");
    }
    }catch(err){
      console.log(err)
    }
  }
  const restartServer = async () => {
    try {
      setForm8Loader({
        loading: true,
      });
      await api({ contentType: true, auth: true }).get('/abotex/serverrestart/server-restart').then((res) => {
        setForm8Loader({
          loading: false,
        });
        toast.success("Server restarted");
      }).catch(err => {
        throw err
      })
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
      setForm8Loader({
        loading: false,
      });
    }
  }
  const getOrderSettings = async () => {
    var get_site_settings = await firestoreDb.collection("site-settings").doc('data');
    var get_tele_settings= await firestoreDb.collection("telegram-settings").doc('data');    
    var docRef=await firestoreDb.collection("site-settings").doc('bot-settings'); 
    var get_straddles_settings = await firestoreDb.collection("site-settings").doc('straddles-settings');
    //var get_trend_settings = await firestoreDb.collection("site-settings").doc('trend-following-settings');
   await api({ contentType: true, auth: true }).get('/abotex/delta/get-admin-trend-settings').then((res) => {
    const trend_settings = res.data.response.data; 
    setTrendSettings(trend_settings);
    });
    // site settings
    get_site_settings.get().then((res) => {
		  if(res.exists)
		  {
			const site_settings = res.data();
 
			  setDiagonalbot(site_settings.enable_diagonal ==1 ? true : false)
			  setStranglebot(site_settings.enable_strangles ==1 ? true : false)
        setStraddlebot(site_settings.enable_straddle ==1 ? true : false)
        setAutomaticStranglebot(site_settings.automatic_strangle_bot == 1 ? true : false)        
        setMarketRangePercentage(site_settings.market_range_percentage)
        setMinimumPremium(site_settings.minimum_premium)
        setSpreadPercentage(site_settings.spread_percentage)
        setStranglePricePercentage(site_settings.strangle_price_difference_percentage)
        setExpiryTimeInterval(site_settings.expiry_time_interval_clock)
        setValue3('market_range_percentage',site_settings.market_range_percentage, { shouldValidate: false });
        setValue3('strangle_price_difference_percentage',site_settings.strangle_price_difference_percentage, { shouldValidate: false });
		  }else{
        setAutomaticStranglebot(false)        
        setDiagonalbot(false)
        setStranglebot(false)
        setStraddlebot(false)
        setMarketRangePercentage('')
        setMinimumPremium(0)
        setSpreadPercentage(100)
        setStranglePricePercentage('')
        setValue3('market_range_percentage','', { shouldValidate: false });
        setValue3('strangle_price_difference_percentage','', { shouldValidate: false });
      }
      
	   });
     // straddles settings
     get_straddles_settings.get().then((res) => {
      if(res.exists)
      {
        const straddle_order_settings = res.data();
        setStraddleBotSettings({
          ...straddleBotSettings,
          'stop_loss_percentage': (straddle_order_settings.stop_loss_percentage) ? straddle_order_settings.stop_loss_percentage : straddleBotSettings.stop_loss_percentage,
          //'trigger_percentage': (straddle_order_settings.trigger_percentage) ? straddle_order_settings.trigger_percentage : straddleBotSettings.trigger_percentage,
          'stop_loss_index_percentage': (straddle_order_settings.stop_loss_index_percentage) ? straddle_order_settings.stop_loss_index_percentage : straddleBotSettings.stop_loss_index_percentage,
          'minimum_take_profit_percentage': (straddle_order_settings.minimum_take_profit_percentage) ? straddle_order_settings.minimum_take_profit_percentage : straddleBotSettings.minimum_take_profit_percentage,
          'trailing_take_profit_percentage': (straddle_order_settings.trailing_take_profit_percentage) ? straddle_order_settings.trailing_take_profit_percentage : straddleBotSettings.trailing_take_profit_percentage,
          'quantity': (straddle_order_settings.quantity) ? straddle_order_settings.quantity : straddleBotSettings.quantity,
          automatic_straddle_bot: straddle_order_settings.automatic_straddle_bot ? straddle_order_settings.automatic_straddle_bot : straddleBotSettings.automatic_straddle_bot,
          autobot_price_difference_percentage:straddle_order_settings.autobot_price_difference_percentage?straddle_order_settings.autobot_price_difference_percentage: straddleBotSettings.autobot_price_difference_percentage,
          autobot_expiry_time_interval_clock:straddle_order_settings.autobot_expiry_time_interval_clock?straddle_order_settings.autobot_expiry_time_interval_clock: straddleBotSettings.autobot_expiry_time_interval_clock,
          'spread_percentage': (straddle_order_settings.spread_percentage) ? straddle_order_settings.spread_percentage : straddleBotSettings.spread_percentage,
          'minimum_premium': (straddle_order_settings.minimum_premium) ? straddle_order_settings.minimum_premium : straddleBotSettings.minimum_premium,
          'combined_sl': (straddle_order_settings.combined_sl) ? straddle_order_settings.combined_sl : straddleBotSettings.combined_sl,
          'combined_sl_percentage':straddle_order_settings.combined_sl_percentage?straddle_order_settings.combined_sl_percentage:straddleBotSettings.combined_sl_percentage,
          'combined_trailing_take_profit_percentage':straddle_order_settings.combined_trailing_take_profit_percentage?straddle_order_settings.combined_trailing_take_profit_percentage:straddleBotSettings.combined_trailing_take_profit_percentage,
          'combined_trailing_stop_price_percentage':straddle_order_settings.combined_trailing_stop_price_percentage?straddle_order_settings.combined_trailing_stop_price_percentage:straddleBotSettings.combined_trailing_stop_price_percentage,
          'Sl_trigger_take_profit':(straddle_order_settings.Sl_trigger_take_profit? straddle_order_settings.Sl_trigger_take_profit: straddleBotSettings.Sl_trigger_take_profit), 
        });
        setstraddleswait_time_reentry(straddle_order_settings.wait_time_reentry? straddle_order_settings.wait_time_reentry : "0");
        setstraddlereentry_enabled(straddle_order_settings.reentry_enabled? (straddle_order_settings.reentry_enabled == 1 ? true : false):false);
        settime_to_close_orders_before_expiry_straddles(straddle_order_settings.time_to_close_orders_before_expiry? straddle_order_settings.time_to_close_orders_before_expiry : "0");
        setTraillingStraddles(straddle_order_settings.combined_trailing_profit? (straddle_order_settings.combined_trailing_profit == 1 ? true : false):false);

        setSlTriggerStraddles(straddle_order_settings && straddle_order_settings.Sl_trigger_take_profit !== undefined ? straddle_order_settings.Sl_trigger_take_profit: true);
        
      }

    })
    // get_trend_settings.get().then((res)=>{
    //   if(res.exists)
    //   {
    //     const trend_settings = res.data();
    //     console.log("trend_settings",trend_settings);
    //     setTrendSettings(trend_settings);
  
        
    //   }else{

    //   }

    // })

    // telegram settings
    get_tele_settings.get().then((res) => {
    if(res.exists)
    {
      const telegram_settings = res.data();
      let disply_tele_token;
      if(telegram_settings.admin_tele_token)
      {
        var key_secret = __KEY_SECRET__;
        var decry_tele_token  = CryptoJS.AES.decrypt(telegram_settings.admin_tele_token, key_secret);      
        var decryptedTeleToken = JSON.parse(decry_tele_token.toString(CryptoJS.enc.Utf8));   
        disply_tele_token = decryptedTeleToken.slice(0,3)+'*****'+decryptedTeleToken.slice(-3);
      }
            
      setValue4('sell_order_msg',telegram_settings.sell_order_msg, { shouldValidate: false });
      setValue4('buy_order_msg',telegram_settings.buy_order_msg, { shouldValidate: false });
      setValue4('position_order_msg',telegram_settings.position_order_msg, { shouldValidate: false });
      setValue4('sl_order_msg',telegram_settings.sl_order_msg, { shouldValidate: false });
      setValue4('trigger_order_msg',telegram_settings.trigger_order_msg, { shouldValidate: false });
      setValue4('cancelled_order_msg',telegram_settings.cancelled_order_msg, { shouldValidate: false });
      setValue4('mismatch_order_msg',telegram_settings.mismatch_order_msg, { shouldValidate: false });
      setValue4('trend_signal_msg',telegram_settings.trend_signal_msg, { shouldValidate: false });
      setValue4('universel_order_msg',telegram_settings.universel_order_msg, { shouldValidate: false });
      setValue4('filled_order_msg',telegram_settings.filled_order_msg, { shouldValidate: false });
      setValue4('roll_order_msg',telegram_settings.roll_order_msg, { shouldValidate: false });
      setValue4('profit_order_msg',telegram_settings.profit_order_msg, { shouldValidate: false });
      setValue4('admin_tele_chatid',telegram_settings.admin_tele_chatid, { shouldValidate: false });
      setValue4('admin_tele_token',disply_tele_token, { shouldValidate: false });
      setTelegramStatus(telegram_settings.telegram_status == 1 ? true : false);
      setAdminOrderStatus(telegram_settings.allow_automatic_bot_msg == 1 ? true : false);
      setAdminBotMsg(telegram_settings.allow_admin_bot_msg == 1 ? true : false);
      setUserProfitMsgStatus(telegram_settings.user_profit_msg_status == 1 ? true : false);
      setUserProfitMsgTime(telegram_settings.user_profit_msg_time);
    }
    else
    {
      setValue4('sell_order_msg','', { shouldValidate: false });
      setValue4('buy_order_msg','', { shouldValidate: false });
      setValue4('position_order_msg','', { shouldValidate: false });
      setValue4('sl_order_msg','', { shouldValidate: false });
      setValue4('trigger_order_msg','', { shouldValidate: false });
      setValue4('cancelled_order_msg','', { shouldValidate: false });
      setValue4('mismatch_order_msg','', { shouldValidate: false });
      setValue4('trend_signal_msg','', { shouldValidate: false });
      setValue4('universel_order_msg','', { shouldValidate: false });
      setValue4('filled_order_msg','', { shouldValidate: false });
      setValue4('admin_tele_chatid','', { shouldValidate: false });
      setValue4('admin_tele_token','', { shouldValidate: false });
      setValue4('roll_order_msg','', { shouldValidate: false });
      setValue4('profit_order_msg','', { shouldValidate: false });
      setTelegramStatus(false);
      setAdminOrderStatus(false);
      setAdminBotMsg(false);
      setUserProfitMsgStatus(false);
      setUserProfitMsgTime('5m')
    }
    }).catch((e)=>{
      console.log('telegram error :',e)
    });


    // bot settings
    docRef.get().then((doc) => {
      if (doc.exists) {
        const order_settings = doc.data();
        setIndexPercentage(order_settings.index_percentage ? order_settings.index_percentage : "10");
        setStopLossPercentage(order_settings.stop_loss_percentage ? order_settings.stop_loss_percentage : "30");
		    setStopLossIndexPercentage(order_settings.stop_loss_index_percentage ? order_settings.stop_loss_index_percentage : "0");  
        setTakeProfitPercentage(order_settings.take_profit_percentage ? order_settings.take_profit_percentage : "0");
        setRollPercentage(order_settings.roll_percentage ? order_settings.roll_percentage : "0");
        setQuantity(order_settings.quantity ? order_settings.quantity : "1");        
        setIndexDistance(order_settings.index_distance ? order_settings.index_distance : "0");        
		    setAutoMarketFill(order_settings.autoMarketFill == 0 ? false:true);
        setRollStatus(order_settings.roll_status == 0 ? false:true);        
        setUniversalStop(order_settings.universal_stop == 1 ? true:false);
        setQtyTime(order_settings.Qty_time_clock);
        setRollTime(order_settings.Roll_time_clock);                
        setValue2('index_distance',order_settings.index_distance, { shouldValidate: false });       
        setStrangleMinTakeProfit(order_settings.min_take_profit_percentage ? order_settings.min_take_profit_percentage : "0");   
        setStrangleTrailTakeProfit(order_settings.trailing_take_profit_percentage ? order_settings.trailing_take_profit_percentage : "0");  
        setCombinedSl(order_settings.combined_sl_percentage? order_settings.combined_sl_percentage:0);
        setCombinedTrailProfitPercentage(order_settings.combined_trailing_profit_percentage? order_settings.combined_trailing_profit_percentage:0);
        setCombinedTrailSl(order_settings.combined_trailing_stop_price_percentage? order_settings.combined_trailing_stop_price_percentage:0);  
        setCombinedProfitPercentage(order_settings.combined_take_profit_percentage? order_settings.combined_take_profit_percentage:0);
        setTraillingStrangles(order_settings.combined_trailing_profit? (order_settings.combined_trailing_profit == 1 ? true : false):false);
        setSlTriggerStrangles(order_settings.Sl_trigger_take_profit? order_settings.Sl_trigger_take_profit:true); 
        setreentry_enabled(order_settings.reentry_enabled? (order_settings.reentry_enabled == 1 ? true : false):false);
        setstrangleswait_time_reentry(order_settings.wait_time_reentry? order_settings.wait_time_reentry : "0");
        settime_to_close_orders_before_expiry(order_settings.time_to_close_orders_before_expiry? order_settings.time_to_close_orders_before_expiry : "0");
      }else{        
		    setIndexPercentage("10");
        setStopLossPercentage("30");
		    setStopLossIndexPercentage("0");  
        setTakeProfitPercentage("0");
        setRollPercentage("0");
        setIndexDistance("0");
        setQuantity("1");
        setAdminStrangleQty("1");
        setAdminDiagonalQty("1");
        settime_to_close_orders_before_expiry("0");
        setRollStatus(false);
        setAllowAdminStatus(false);
        setUniversalStop(false);
        setAutoMarketFill(false);		 
        setValue2('index_distance',"0", { shouldValidate: true });
        setStrangleMinTakeProfit("0");
        setStrangleTrailTakeProfit("0");
        setCombinedSl(0);
        setCombinedTrailProfitPercentage(0);
        setCombinedTrailSl(0);  
        setCombinedProfitPercentage(0);
        setreentry_enabled(false);
        setstrangleswait_time_reentry("0");
	  }
    }).catch((e)=>{
      
      setPageLoader({        
          loading: false,
        });
    });

  }
  
  useEffect(() => {
    getConfig();
    getOrderSettings();
    setTeleMsg(telegramMsg());
    setTeleIDMsg(telegramIDMsg());
    setTeleChatIDMsg(telegramChatIDMsg());
    setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit());
    setMidPrizeMsg(MidPrize());
    getTredviewURL();
    getCCXTSettings();
    getServerIP();
  }, []);
const handleWheel = (e) => {
    e.target.blur();
  };
  if (ToolTipData=="" && ToolTipData.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="DeriSnap" breadcrumbItem="Settings" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="2" sm="3">
                <Nav className="flex-column" pills>
                <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        setactiveTab("4")
                      }}
                    >
                      <i className="mdi mdi-alpha-s-box d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Strangles Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3")
                      }}
                    >
                      <i className="mdi mdi-alpha-d-box d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Diagonals Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2")
                      }}
                    >
                      <i className="bx bx-cube d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Straddles Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "6" })}
                      onClick={() => {
                        setactiveTab("6")
                      }}
                    >
                      <i className="bx bx-line-chart d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Trend following Bot</p>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "8" })}
                      onClick={() => {
                        setactiveTab("8")
                      }}
                    >
                      <i className="fas fa-arrows-alt-h d-block check-nav-icon mt-4 mb-2"/> 
                      <p className="fw-bold mb-4">CCXT</p> 
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1")
                      }}
                    >
                      <i className="bx bxs-lock d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">API</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "5" })}
                      onClick={() => {
                        setactiveTab("5")
                      }}
                    >
                      <i className="bx bxl-telegram d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Telegram</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "7" })}
                      onClick={() => {
                        setactiveTab("7")
                      }}
                    >
                      <i className="fas fa-server d-block check-nav-icon mt-3 mb-3" />
                      <p className="fw-bold mb-4">Server</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10" sm="9">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div>
                          <CardTitle>API Settings</CardTitle>
                          <p className="card-title-desc">
                          Changing the key & secret will disconnect the active calls in our bot, so do not change the key & secret without support team.
                          </p>
                          <form key={1} onSubmit={handleSubmit(keySettingSubmit)}>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="2"
                                className="col-form-label"
                              >
                                API Key <i className="bx bx-info-circle" id ="apikey" />
                                    <UncontrolledTooltip placement="top" target="apikey"> 
                                        {ToolTipData.api_key}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="10">
                                <input
                                  type="text"
                                  name="api_key"
                                  className="form-control"                                  
                                  placeholder="Enter your key"
                                  {...register("api_key", { required: true})}
                                  autoComplete="off" 
                                />
                                {errors.api_key && <small className="text-danger">Please provide a api Key</small>}
                                
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="2"
                                className="col-form-label"
                              >
                                API Secret <i className="bx bx-info-circle" id ="apisecret" />
                                    <UncontrolledTooltip placement="top" target="apisecret">
                                        {ToolTipData.api_secret}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="10">
                                <input
                                  type="text"
                                  name="api_secret"
                                  className="form-control"                                  
                                  placeholder="Enter your secret"
                                  {...register("api_secret", { required: true})}
                                  autoComplete="off" 
                                />
                                {errors.api_secret && <small className="text-danger">Please provide a api secret</small>}
                              </Col>
                            </FormGroup> 
                            <div className="text-sm-end">
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form1_loader.loading}>
                              {form1_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                            </div>
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div>
                          <CardTitle>Straddles Bot Settings</CardTitle>
                          <p className="card-title-desc">
                            You can also change these settings while starting a straddles bot.
                          </p>
                          <form key={5} onSubmit={handleSubmit5(straddlesSettingSubmit)}>
                            <Row>
                            <Col lg="3">
                              <FormGroup className=" mt-4 mb-0">
                                <Label htmlFor="expirydateInput">
                                Enable Straddle Strategy <i className="bx bx-info-circle" id ="SBSESS" />
                                    <UncontrolledTooltip placement="top" target="SBSESS">
                                        {ToolTipData.admin_enable_straddle_strategy}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col md="2">
                                  <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2"                                      
                                  onChange={(e) => setStraddlebot(e)}
                                  checked={straddlebot} 
                                />
                                </Col>
                              </FormGroup>
                            </Col>  
                            </Row>
                          <Row>
                          <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Quantity
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="QUANTITY01" />
                                    <UncontrolledTooltip placement="top" target="QUANTITY01"> 
                                        {ToolTipData.quantity}
                                    </UncontrolledTooltip>
                                    <Slider value={straddleBotSettings.quantity?straddleBotSettings.quantity:1} min={1} max={1000} step={1} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, quantity: value })}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"  min={0} max={1000} step={1} value={straddleBotSettings.quantity ? straddleBotSettings.quantity : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, quantity: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, quantity: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_quantity" className="form-control" />
                                  </FormGroup>
                              </Col>
                              {/* <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Trigger %
                                    </Label>{" "}
                                  <i className="bx bx-info-circle" id ="Trigger" />
                                    <UncontrolledTooltip placement="top" target="Trigger">
                                        {ToolTipData.Trigger}
                                    </UncontrolledTooltip>
                                    <Slider value={straddleBotSettings.trigger_percentage?straddleBotSettings.trigger_percentage:0} min={0} max={100} step={0.5} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, trigger_percentage: value })}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"  min={0} max={100} step={0.5} value={straddleBotSettings.trigger_percentage ? straddleBotSettings.trigger_percentage : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, trigger_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, trigger_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_trigger_percentage" className="form-control" />
                                  </FormGroup>
                              </Col> */}
                          <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Market Stop Loss Percentage
                                    {" "}
                                  <i className="bx bx-info-circle" id ="StopLoss" /></Label>
                                    <UncontrolledTooltip placement="top" target="StopLoss">
                                        {ToolTipData.stop_loss}
                                    </UncontrolledTooltip>
                                    <Slider value={straddleBotSettings.stop_loss_percentage?straddleBotSettings.stop_loss_percentage:0} min={0} max={100} step={0.5} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, stop_loss_percentage: value })}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"  min={0} max={100} step={0.5} value={straddleBotSettings.stop_loss_percentage ? straddleBotSettings.stop_loss_percentage : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, stop_loss_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, stop_loss_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_stop_loss_percentage" className="form-control" />
                                  </FormGroup>
                                  {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                  <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="combined_sl"
                                  checked={straddleBotSettings.combined_sl ? straddleBotSettings.combined_sl : false}
                                  onChange={(event) => setStraddleBotSettings({ ...straddleBotSettings, combined_sl: event.target.checked })}
                                  />
                                  <label className="form-check-label" htmlFor="combined_sl">
                                  Combined Stop Loss
                                  </label>{" "}<i className="bx bx-info-circle" id ="Stop_loss_combined" />
                                                                                                <UncontrolledTooltip placement="top" target="Stop_loss_combined"> 
                                                                                                    {ToolTipData.stop_loss_combined}
                                                                                                </UncontrolledTooltip>
                                  </div> */}
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Index Price Range % for Stop Loss
                                    </Label>{" "}
                                  <i className="bx bx-info-circle" id ="pricerange" />
                                    <UncontrolledTooltip placement="top" target="pricerange">
                                        {ToolTipData.stop_loss_index_percentage}
                                    </UncontrolledTooltip>
                                    <Slider value={straddleBotSettings.stop_loss_index_percentage?straddleBotSettings.stop_loss_index_percentage:0} min={0} max={100} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, stop_loss_index_percentage: value })}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   max={100} value={straddleBotSettings.stop_loss_index_percentage ? straddleBotSettings.stop_loss_index_percentage : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, stop_loss_index_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, stop_loss_index_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_stop_loss_index_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Trailing Take Profit Percentage
                                    </Label>{" "}<i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i>
                                    <Slider value={straddleBotSettings.minimum_take_profit_percentage?straddleBotSettings.minimum_take_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, minimum_take_profit_percentage: value })}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"  min={0} max={100} step={0.5} value={straddleBotSettings.minimum_take_profit_percentage ? straddleBotSettings.minimum_take_profit_percentage : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, minimum_take_profit_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, minimum_take_profit_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_minimum_take_profit_percentage" className="form-control" />
                                  <p className="text-muted mx-1 font-size-11">Note: At which the Trailing take profit will become active</p>
                                  </FormGroup>
                                  <div className="form-check form-checkbox-outline form-check-success mt-3">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="straddlesMin"
                                    checked={SlTriggerStraddles}
                                    onClick={(event) => {setSlTriggerStraddles(event.target.checked);setStraddleBotSettings({ ...straddleBotSettings, Sl_trigger_take_profit: event.target.checked });}}
                                    />
                                    <label className="form-check-label" htmlFor="straddlesMin">
                                    Activate trailing take profit when stop loss triggered on one leg
                                    </label>{" "}
                                    {/* <i className="bx bx-info-circle" id ="STSLTMTP" />
                                    <UncontrolledTooltip placement="top" target="STSLTMTP"> 
                                        {ToolTipData.stop_loss_trigger_market_take_profit}
                                    </UncontrolledTooltip>  */}
                                </div>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Trailing Stop Price Percentage
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i>
                                    <Slider value={straddleBotSettings.trailing_take_profit_percentage?straddleBotSettings.trailing_take_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, trailing_take_profit_percentage: value })}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"  min={0} max={100} step={0.5} value={straddleBotSettings.trailing_take_profit_percentage ? straddleBotSettings.trailing_take_profit_percentage : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, trailing_take_profit_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, trailing_take_profit_percentage: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_trailing_take_profit_percentage" className="form-control" />
                                  <p className="text-muted mx-1 font-size-11">Note: Active after minimum take profit is reached</p>
                                </FormGroup>   
                                {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="StarangleTrailling"
                                    checked={TraillingStraddles}
                                    onChange={(event) => {setTraillingStraddles(event.target.checked),setStraddleBotSettings({ ...straddleBotSettings, combined_trailing_profit: event.target.checked == true ? 1 : 0 })}}
                                    />
                                    <label className="form-check-label" htmlFor="StarangleTrailling">
                                    Combined Profit
                                    </label>{" "}
                                </div>                                       */}
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="starddlesrow-combined-sl">
                                  Combined Stop Loss %
                                  {" "}
                                  <i className="bx bx-info-circle" id ="straddles-combinedsl-trigger" />
                                    <UncontrolledTooltip placement="top" target="straddles-combinedsl-trigger">
                                        {ToolTipData.stop_loss_combined}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Slider value={straddleBotSettings.combined_sl_percentage?straddleBotSettings.combined_sl_percentage:0} min={0} max={100} step={0.5} onChange={value => { setStraddleBotSettings({
                                      ...straddleBotSettings,
                                      combined_sl_percentage: value
                                    });}} />
                                  <input  type="number" onWheel={handleWheel}  max={100} step={0.5} value={straddleBotSettings.combined_sl_percentage} onChange={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100;
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setStraddleBotSettings({
                                      ...straddleBotSettings,
                                      combined_sl_percentage: newValue
                                    });
                                  }} onBlur={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100;
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setStraddleBotSettings({
                                      ...straddleBotSettings,
                                      combined_sl_percentage: newValue
                                    });
                                  }} name="straddle_combined_sl_percentage" className="form-control" />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="starddlesrow-combined-profit">
                                  Combined Trailing Take Profit %  {" "}
                                  <i className="bx bx-info-circle" id ="straddles-combined-trail-pro-trigger" />
                                    <UncontrolledTooltip placement="top" target="straddles-combined-trail-pro-trigger">
                                        {ToolTipData.combined_trailing_profit_percentage}
                                    </UncontrolledTooltip>
                                 </Label>
                                 <Slider value={straddleBotSettings.combined_trailing_take_profit_percentage?straddleBotSettings.combined_trailing_take_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => { setStraddleBotSettings({
                                    ...straddleBotSettings,
                                    combined_trailing_take_profit_percentage: value
                                  });}} />
                                  <input  type="number" onWheel={handleWheel}  max={100} step={0.5} value={straddleBotSettings.combined_trailing_take_profit_percentage} onChange={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setStraddleBotSettings({
                                    ...straddleBotSettings,
                                    combined_trailing_take_profit_percentage: newValue
                                  });
                                }} onBlur={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setStraddleBotSettings({
                                    ...straddleBotSettings,
                                    combined_trailing_take_profit_percentage: newValue
                                  });
                                }} name="straddle_combined_trailing_take_profit_percentage" className="form-control" />
                                </FormGroup>
                               
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="starddlesrow-combined-sp">
                                  Combined Trailing Stop Price %  {" "}
                                  <i className="bx bx-info-circle" id ="straddles-combined-trail-stop-trigger" />
                                    <UncontrolledTooltip placement="top" target="straddles-combined-trail-stop-trigger">
                                        {ToolTipData.combined_trailing_stop_price_percentage}
                                    </UncontrolledTooltip>
                                 </Label>
                                 <Slider value={straddleBotSettings.combined_trailing_stop_price_percentage?straddleBotSettings.combined_trailing_stop_price_percentage:0} min={0} max={100} step={0.5} onChange={value => { setStraddleBotSettings({
                                    ...straddleBotSettings,
                                    combined_trailing_stop_price_percentage: value
                                  });}} />
                                  <input  type="number" onWheel={handleWheel}   max={100} step={0.5} value={straddleBotSettings.combined_trailing_stop_price_percentage} onChange={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setStraddleBotSettings({
                                    ...straddleBotSettings,
                                    combined_trailing_stop_price_percentage: newValue
                                  });
                                }} onBlur={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setStraddleBotSettings({
                                    ...straddleBotSettings,
                                    combined_trailing_stop_price_percentage: newValue
                                  });
                                }} name="straddle_combined_trailing_stop_price_percentage" className="form-control" />
                                </FormGroup>
                               
                              </Col>

                              <h5 className="mt-5 mb-3 font-size-15">
                              Re-entry Settings
                                </h5>
                                <div className="p-4 border">
                                  <Row>
                              <Col lg="2">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Enable Re-Entry
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="DHOSLIOLreentry_enabled" />
                                    <UncontrolledTooltip placement="top" target="DHOSLIOLreentry_enabled">
                                        {ToolTipData.reentry_enabled}
                                    </UncontrolledTooltip>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                 </FormGroup>
                              </Col>
                              <Col md="4">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className=" mt-4 mb-sm-8 mb-0"                                      
                                      onChange={(e) => setstraddlereentry_enabled(e)} 
                                      checked={straddlereentry_enabled}
                                    />
                              </Col>
                                  <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Waiting time for Re-Entry (H : M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="wait_time_reentry" />
                                    <UncontrolledTooltip placement="top" target="wait_time_reentry"> 
                                        {ToolTipData.wait_time_reentry}
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'straddleswait_time_reentry')}
                                          value={toHoursAndMinutes(straddleswait_time_reentry)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true, 
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  </Row>
                                  <Row><Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Time to Close Orders Before Expiry (H : M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry" />
                                    <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry"> 
                                        {ToolTipData.time_to_close_orders_before_expiry }
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry_straddles')}
                                          value={toHoursAndMinutes(time_to_close_orders_before_expiry_straddles)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            maxTime: "12:00"
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  </Row>
                                  </div>                              
                                <h5 className="mt-5 mb-3 font-size-15">
                                  Automatic Bot Settings
                                </h5>
                                <div className="p-4 border">
                                <Row>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                    Enable Automatic Mode for Straddles
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="ABSEAMFS" />
                                    <UncontrolledTooltip placement="top" target="ABSEAMFS"> 
                                        {ToolTipData.admin_enable_automatic_straddle}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                      
                                      onChange={(e) => {setStraddleBotSettings({ ...straddleBotSettings, automatic_straddle_bot: e })}}
                                      checked={straddleBotSettings.automatic_straddle_bot}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>                        
                                {straddleBotSettings.automatic_straddle_bot ? 
                                <>                                
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Straddle price difference % <i className="bx bx-info-circle" id ="ABSSPD2" />
                                    <UncontrolledTooltip placement="top" target="ABSSPD2"> 
                                        {ToolTipData.admin_automatic_straddle_price}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Col md="9">                              
                                  <Slider orientation="horizontal"  value={straddleBotSettings.autobot_price_difference_percentage?straddleBotSettings.autobot_price_difference_percentage:0}  onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, autobot_price_difference_percentage: value })}}  />
                                  <input type="number" onWheel={handleWheel}  inputMode="decimal" min="0" max ="100" step="0.0001" value={straddleBotSettings.autobot_price_difference_percentage ? straddleBotSettings.autobot_price_difference_percentage:0}  onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, autobot_price_difference_percentage:  Number(e.target.value) }) }} name="straddle_autobot_price_difference_percentage" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                      Minimum premium <i className="bx bx-info-circle" id ="Minper" />
                                    <UncontrolledTooltip placement="top" target="Minper">
                                        {ToolTipData.admin_automatic_bot_minimum_premium}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Col md="9">                              
                                      <input type="number" onWheel={handleWheel}  inputMode="numeric"   value={straddleBotSettings.minimum_premium ? straddleBotSettings.minimum_premium:''}  name="minimum_premium" className="form-control" onChange={e => {setStraddleBotSettings({ ...straddleBotSettings, minimum_premium: Number(e.target.value) }) }} />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                      Maximum Spread % <i className="bx bx-info-circle" id ="Maxspread" />
                                    <UncontrolledTooltip placement="top" target="Maxspread">
                                        {ToolTipData.admin_automatic_bot_maximum_spread}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Col md="9">                              
                                      <input type="number" onWheel={handleWheel}  inputMode="numeric"   value={straddleBotSettings.spread_percentage ? straddleBotSettings.spread_percentage:''}  name="spread_percentage" className="form-control" onChange={e => {setStraddleBotSettings({ ...straddleBotSettings, spread_percentage: Number(e.target.value) }) }} />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mt-4 mb-0" row>
                                          <Label htmlFor="formrow-firstname-Input"  md="3"  className="col-form-label">
                                          Time interval to start checking New Expiry (H : M) <i className="bx bx-info-circle" id ="TIMEINTER" />
                                    <UncontrolledTooltip placement="top" target="TIMEINTER"> 
                                        {ToolTipData.admin_automatic_bot_waiting_time}
                                    </UncontrolledTooltip>
                                          </Label>  
                                          <Col md="9">                                     
                                          <Flatpickr
                                            className="form-control d-block"
                                            placeholder="Select time"
                                            onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, autobot_expiry_time_interval_clock: new Date(e).getHours() + ':' + new Date(e).getMinutes() + ':' + new Date(e).getSeconds() }) }} 
                                            value={straddleBotSettings.autobot_expiry_time_interval_clock}
                                            options={{                                          
                                              enableTime: true,
                                              noCalendar: true,
                                              dateFormat: "H:i",
                                              time_24hr: true,
                                            }}
                                          />
                                          </Col>
                                        </FormGroup>
                                </>
                                :''}                                
                              </Row>
                                </div>
                                <div className="text-sm-end mt-2">
                                <Button className="btn-label w-md" color="success" type="submit" disabled={form5_loader.loading}>
                                  {form5_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                  <i className="bx bx-smile label-icon"></i> Submit
                                </Button>
                                </div>                              
                            </Row>
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="6">
                        <div> 
                          <CardTitle>Trend Following Bot Settings</CardTitle>
                          <form key={6} onSubmit={handleSubmit3(updateNewTrendSettings)}>
                          <Row>  
                            <Col lg="3">
                              <FormGroup className=" mt-4 mb-0">
                                <Label htmlFor="expirydateInput">
                                  Enable Trend Following Strategy <i className="bx bx-info-circle" id ="ETFS" />
                                    <UncontrolledTooltip placement="top" target="ETFS"> 
                                        {ToolTipData.admin_enable_trendfollowing_strategy}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col md="2">
                                  <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2" 
                                  name="enable_trend_following"                                     
                                  onChange={(e) => handleTrendSettingsSliderChange(e,'enable_trend_following')}
                                  checked={trendSettings.enable_trend_following?true:false} 
                                />
                                </Col>
                              </FormGroup>
                            </Col>
                            </Row> 
                            {trendSettings.enable_trend_following ? 
                            <>    

                            <Row>
                              <Col lg="6">
                                <div className="mt-4">
                                <Label className="form-label">Place Order</Label>{" "}<i className="bx bx-info-circle" id ="Place_Order" />
                                    <UncontrolledTooltip placement="top" target="Place_Order"> 
                                        {ToolTipData.trend_day}
                                    </UncontrolledTooltip>
                                <FormGroup className="input-group mb-0 ">
                                  <select name="trend_day" label="trend_day" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_day}>
                                    <option value='D1' selected={true}>Today</option>
                                    <option value='D2'>Tomorrow </option>
                                    <option value='D3'>Day After Tomorrow </option>
                                    <option value='W1'>Current Week Friday </option>
                                    <option value='W2'>Next Week Friday </option>
                                    <option value='W3'>Next to Next Week Friday </option>
                                    <option value='M1'>Current Month End Friday </option>
                                    <option value='M2'>Next Month End Friday </option>
                                  </select>
                                  </FormGroup>
                                  </div>                                                       
                                </Col>

                                <Col lg="6"> 
                          <div className="mt-4">

                            <div>
                              <Label className="form-label">Distance from index</Label>{" "}
                              <i className="bx bx-info-circle" id ="TFBSDI" />
                                    <UncontrolledTooltip placement="top" target="TFBSDI"> 
                                        {ToolTipData.distance_from_index}
                                    </UncontrolledTooltip>
                                <FormGroup className="input-group mb-0 ">
                                 
                                    
                                    <select
                              className="form-control" name="index_distance_type"  id="inputGroupFileAddon04"  onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.index_distance_type} 
                            >
                              <option value="percentage">%</option>
                              <option value="points">Pts</option>
                            </select>                                  
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={trendSettings.index_distance_type!='points'?100:10000} step={0.5} value={trendSettings.index_distance ? trendSettings.index_distance:''} onChange={(e) => handleTrendSettingsChange(e)}onBlur={(e) => handleTrendSettingsChange(e)} name="index_distance" className="form-control w-50"  aria-describedby="inputGroupFileAddon04" />
                                  </FormGroup> </div></div>
                              </Col>
                              <Col lg="6">
                              <div className="mt-4">
                              <Label className="form-label">Select Side</Label>{" "}
                              <i className="bx bx-info-circle" id ="TFBSDIS" />
                                    <UncontrolledTooltip placement="top" target="TFBSDIS"> 
                                        {ToolTipData.side}
                                    </UncontrolledTooltip>
                              <FormGroup className="input-group mb-0 ">
                                <select name="trend_side" label="trend_side" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_side}>
                                  <option value='sell' selected={true}>Sell</option>
                                  <option value='buy'>Buy</option>
                                  {/* <option value='sell_only'>Sell </option>
                                  <option value='buy_only'>Buy </option> */}
                                  <option value='both'>Both</option>
                                  
                                </select>
                                </FormGroup>
                                </div>                                                       
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Strike Counts
                                    </Label>{" "}
                              <i className="bx bx-info-circle" id ="Strike_Counts" />
                                    <UncontrolledTooltip placement="top" target="Strike_Counts"> 
                                        {ToolTipData.strike_counts}
                                    </UncontrolledTooltip>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={1} max={10} value={trendSettings.strike_count ? trendSettings.strike_count:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="strike_count" className="form-control" />
                                  </FormGroup>
                              </Col>


                           
                         
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Total quantity limit
                                    </Label>{" "}
                              <i className="bx bx-info-circle" id ="TFBSTQL" />
                                    <UncontrolledTooltip placement="top" target="TFBSTQL"> 
                                        {ToolTipData.total_quantity_limit}
                                    </UncontrolledTooltip>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={10000} step={0.5} value={trendSettings.total_quantity_limit ? trendSettings.total_quantity_limit:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="total_quantity_limit" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="6">
                                      <FormGroup className="mt-4 mb-0">
                                          <Label htmlFor="formrow-firstname-Input">
                                          Maximum number of orders from same trend signal
                                            </Label>{" "}
                                         {/*  <i className="bx bx-info-circle" id ="NOOFTRADE" />
                                            <UncontrolledTooltip placement="top" target="NOOFTRADE"> 
                                                {ToolTipData.same_trend_no_of_trade}
                                        </UncontrolledTooltip> */ }
                                            <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={10000} step={0.5} value={trendSettings.same_trend_no_of_trade ? trendSettings.same_trend_no_of_trade:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="same_trend_no_of_trade" className="form-control" />
                                      </FormGroup>
                                    </Col>
                             
                            
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Market Stop Loss Percentage
                                    {" "}
                              <i className="bx bx-info-circle" id ="TFBSSL" /></Label>
                                    <UncontrolledTooltip placement="top" target="TFBSSL"> 
                                        {ToolTipData.stop_loss}
                                    </UncontrolledTooltip>
                                    <Slider value={trendSettings.stop_loss_percentage?trendSettings.stop_loss_percentage:0} min={0} max={100} step={0.5} onChange={value => handleTrendSettingsSliderChange(value,'stop_loss_percentage')} />
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={trendSettings.stop_loss_percentage ? trendSettings.stop_loss_percentage:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="stop_loss_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Trailing Take Profit  %
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i>
                                    <Slider value={trendSettings.min_take_profit_percentage?trendSettings.min_take_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => handleTrendSettingsSliderChange(value,'min_take_profit_percentage')} />
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={trendSettings.min_take_profit_percentage ? trendSettings.min_take_profit_percentage:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="min_take_profit_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>
                             
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Trailing Stop Price %
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i>
                                    <Slider value={trendSettings.trailing_take_profit_percentage?trendSettings.trailing_take_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => handleTrendSettingsSliderChange(value,'trailing_take_profit_percentage')} />
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={trendSettings.trailing_take_profit_percentage ? trendSettings.trailing_take_profit_percentage:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="trailing_take_profit_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Make Limit Orders x% from Mid/Mark Price:
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleMidPrize(!visibleMidPrize)}></i>
                                    <Slider value={trendSettings.mid_price_percentage?trendSettings.mid_price_percentage:0} min={0} max={1000} step={0.5} onChange={value => handleTrendSettingsSliderChange(value,'mid_price_percentage')} />
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={1000} step={0.5} value={trendSettings.mid_price_percentage ? trendSettings.mid_price_percentage:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="mid_price_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>
                             
                             

                              <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Waiting Time  for entries (H : M)
                                        </Label> {" "}
                              <i className="bx bx-info-circle" id ="TFBSTFE" />
                                    <UncontrolledTooltip placement="top" target="TFBSTFE"> 
                                        {ToolTipData.time_for_entry}
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForPrice"
                                          onChange={(e) => handleTrendSettingsTimeChange(e,'waitTimeForPrice')}
                                          value={trendSettings.waitTimeForPrice_clock}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col> 
                             
                               <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Waiting Time  for re-entries (H : M)
                                        </Label> {" "}
                              <i className="bx bx-info-circle" id ="TFBSTre-entries" />
                                    <UncontrolledTooltip placement="top" target="TFBSTre-entries"> 
                                        {ToolTipData.time_for_reentries}
                                    </UncontrolledTooltip>                                   
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForReEntry"
                                          onChange={(e) => handleTrendSettingsTimeChange(e,'waitTimeForReEntry')}
                                          value={trendSettings.waitTimeForReEntry_clock}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col> 
                                  <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Waiting Time  for re-entries after stop loss (H : M)
                                        </Label>{" "}
                              <i className="bx bx-info-circle" id ="TFBSTRASL" />
                                    <UncontrolledTooltip placement="top" target="TFBSTRASL"> 
                                        {ToolTipData.time_for_reentries_after_stop_loss}
                                    </UncontrolledTooltip>                                   
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeAfterStopLoss"
                                          onChange={(e) => handleTrendSettingsTimeChange(e,'waitTimeAfterStopLoss')}
                                          value={trendSettings.waitTimeAfterStopLoss_clock}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col> 
                                 
                                    <Col lg="6">
                                      <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Order Interval for same trend signal (H : M)
                                        </Label>{" "}
                                        {/*<i className="bx bx-info-circle" id ="TRADEIRTV" />
                                            <UncontrolledTooltip placement="top" target="TRADEIRTV"> 
                                                {ToolTipData.Duration}
                                            </UncontrolledTooltip> */}
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          onChange={(e) => handleTrendSettingsTimeChange(e,'same_trend_trade_interval')}
                                          value={trendSettings.same_trend_trade_interval_clock}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                          </FormGroup>
                                    </Col>
                                    
                                    </Row>
                                    <Col>
                                      <FormGroup className="mb-4 mt-4" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="5"
                                          className="col-form-label"
                                        >
                                          Close Existing Positions and Open Orders on Trend Change <i className="bx bx-info-circle" id ="TFBSCOOONS" />
                                    <UncontrolledTooltip placement="top" target="TFBSCOOONS"> 
                                        {ToolTipData.close_old_positions_trend_change}
                                    </UncontrolledTooltip>
                                    </Label>
                                        <Col md="7">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          //onColor="#626ed4" 
                                          name="close_old_positions"
                                          onChange={(e) => handleTrendSettingsSliderChange(e,'close_old_positions')}
                                          checked={trendSettings.close_old_positions} 
                                        />
                                        </Col>
                                      </FormGroup>
                                    </Col>

                                    <Col>
                                      <FormGroup className="mb-4 mt-4" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="5"
                                          className="col-form-label"
                                        >
                                         Close Open Orders on Trend Reversal <i className="bx bx-info-circle" id ="TFBSCOPOTC" />
                                    <UncontrolledTooltip placement="top" target="TFBSCOPOTC"> 
                                        {ToolTipData.close_old_orders_only_open_orders}
                                    </UncontrolledTooltip>    
                                        </Label>
                                        <Col md="7">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          //onColor="#626ed4" 
                                          name="close_old_positions_trend_change"
                                          onChange={(e) => handleTrendSettingsSliderChange(e,'close_old_positions_trend_change')}
                                          checked={trendSettings.close_old_positions_trend_change} 
                                        />
                                        </Col>
                                      <Col  lg="4">
                                      <FormGroup className="mb-4 mt-4" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="6"
                                          className="col-form-label"
                                        >Close Open Orders on Trend Reversal  <i className="bx bx-info-circle" id ="TFBSCOPOTC111" />
                                    <UncontrolledTooltip placement="top" target="TFBSCOPOTC111"> 
                                        {ToolTipData.close_old_orders_only_open_orders}
                                    </UncontrolledTooltip> 
                                        </Label>
                                        <Col md="1">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          //onColor="#626ed4" 
                                          onChange={(e) => handleTrendSettingsSliderChange(e , 'close_old_orders_only_open_orders')} 
                                          checked={trendSettings.close_old_orders_only_open_orders} 
                                        />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                      </FormGroup>
                                    </Col>
                                  
                                  
                          <Row>  
                           
                          <FormGroup className="mb-4 mt-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="6"
                                className="col-form-label"
                              >Enable Automatic bot 
                               {/* <i className="bx bx-info-circle" id ="TFBSCOPOTC111" />
                          <UncontrolledTooltip placement="top" target="TFBSCOPOTC111"> 
                              {ToolTipData.close_old_orders_only_open_orders}
                          </UncontrolledTooltip>  */}
                              </Label>
                            
                                <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                //onColor="#626ed4" 
                                onChange={(e) => handleTrendSettingsSliderChange(e , 'enable_automatic_trendfollowing')} 
                                checked={trendSettings.enable_automatic_trendfollowing} 
                              />
                              
                            </FormGroup>
                             <FormGroup className="mb-4 mt-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                                  Method for Trend signal: <i className="bx bx-info-circle" id ="TFBSMTS" />
                                    <UncontrolledTooltip placement="top" target="TFBSMTS"> 
                                        {ToolTipData.method_for_trend_signal}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="5">
                            <select name="trend_method" label="trend_method" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_method}> 
                                           						  
                              <option value='sma'>SMA crossovers</option>	
                              <option value='ema'>EMA crossovers</option>				  
                              <option value='macd'>MACD Signal Line cross </option>					  
                              <option value='supertrend'>Supertrend</option>
                          </select>
                          </Col>
                              </FormGroup>
                              {!trendSettings.trend_method || trendSettings.trend_method=="sma"? 
                              <>
                              
                              <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Interval <i className="bx bx-info-circle" id ="TFBSINTREVAL" />
                                    <UncontrolledTooltip placement="top" target="TFBSINTREVAL"> 
                                        {ToolTipData.sma_crossover.interval}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <select name="time_frame" label="time_frame" required className="form-select" value={trendSettings.time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                <option value='1m'>1 minute</option>
                                <option value='5m'>5 minutes</option>
                                <option value='15m'>15 minutes</option>
                                <option value='30m'>30 minutes</option>
                                <option value='1h'>1 hour</option>
                                <option value='2h'>2 hours</option>
                                <option value='4h'>4 hours</option>
                                <option value='1d'>1 day</option>
                                <option value='1w'>1 week</option>
                                <option value='1M'>1 month</option>
                              </select>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Short length <i className="bx bx-info-circle" id ="TFBSSORTLENGTH" />
                                    <UncontrolledTooltip placement="top" target="TFBSSORTLENGTH"> 
                                        {ToolTipData.sma_crossover.short_length}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.short_term?trendSettings.short_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="short_term" className="form-control" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Long length <i className="bx bx-info-circle" id ="TFBSLONGLENGTH" />
                                    <UncontrolledTooltip placement="top" target="TFBSLONGLENGTH"> 
                                        {ToolTipData.sma_crossover.long_length}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.long_term?trendSettings.long_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="long_term" className="form-control" />
                              </Col>
                            </FormGroup>
                              </> :trendSettings.trend_method=="ema"? 
                               <>
                              
                               <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="3"
                                 className="col-form-label"
                               >
                               Interval <i className="bx bx-info-circle" id ="TFBMINTREVAL1" />
                                     <UncontrolledTooltip placement="top" target="TFBMINTREVAL1"> 
                                         {ToolTipData.ema_crossover.interval}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="9">                              
                               <select name="ema_time_frame" label="ema_time_frame" required className="form-select" value={trendSettings.ema_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                 <option value='1m'>1 minute</option>
                                 <option value='5m'>5 minutes</option>
                                 <option value='15m'>15 minutes</option>
                                 <option value='30m'>30 minutes</option>
                                 <option value='1h'>1 hour</option>
                                 <option value='2h'>2 hours</option>
                                 <option value='4h'>4 hours</option>
                                 <option value='1d'>1 day</option>
                                 <option value='1w'>1 week</option>
                                 <option value='1M'>1 month</option>
                               </select>
                               </Col>
                             </FormGroup>
                             <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="3"
                                 className="col-form-label"
                               >
                               Short length <i className="bx bx-info-circle" id ="ema_short" />
                                     <UncontrolledTooltip placement="top" target="ema_short"> 
                                         {ToolTipData.ema_crossover.short_length}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="9">                              
                               <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.ema_short_term?trendSettings.ema_short_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_short_term" className="form-control" />
                               </Col>
                             </FormGroup>
                             <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="3"
                                 className="col-form-label"
                               >
                               Long length <i className="bx bx-info-circle" id ="ema_long" />
                                     <UncontrolledTooltip placement="top" target="ema_long"> 
                                         {ToolTipData.ema_crossover.long_length}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="9">                              
                               <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.ema_long_term?trendSettings.ema_long_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_long_term" className="form-control" />
                               </Col>
                             </FormGroup>
                               </>  : trendSettings.trend_method=="macd"? 
                              <>
                              
                              <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                             Interval <i className="bx bx-info-circle" id ="TFBSMACDINTERVAL" />
                                    <UncontrolledTooltip placement="top" target="TFBSMACDINTERVAL"> 
                                        {ToolTipData.macd_sign_line_cross.interval}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <select name="macd_time_frame" required label="macd_time_frame" className="form-select" value={trendSettings.macd_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                <option value='1m'>1 minute</option>
                                <option value='5m'>5 minutes</option>
                                <option value='15m'>15 minutes</option>
                                <option value='30m'>30 minutes</option>
                                <option value='1h'>1 hour</option>
                                <option value='2h'>2 hours</option>
                                <option value='4h'>4 hours</option>
                                <option value='1d'>1 day</option>
                                <option value='1w'>1 week</option>
                                <option value='1M'>1 month</option>
                              </select>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Short length <i className="bx bx-info-circle" id ="TFBSSORTL" />
                                    <UncontrolledTooltip placement="top" target="TFBSSORTL"> 
                                        {ToolTipData.macd_sign_line_cross.short_length}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.macd_short_term?trendSettings.macd_short_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_short_term" className="form-control" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Long length <i className="bx bx-info-circle" id ="TFBSLONGL" />
                                    <UncontrolledTooltip placement="top" target="TFBSLONGL"> 
                                        {ToolTipData.macd_sign_line_cross.long_length}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.macd_long_term?trendSettings.macd_long_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_long_term" className="form-control" />
                              </Col>
                            </FormGroup>
                              
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Signal length  <i className="bx bx-info-circle" id ="TFBSSIGNALL" />
                                    <UncontrolledTooltip placement="top" target="TFBSSIGNALL"> 
                                        {ToolTipData.macd_sign_line_cross.signal_length}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.macd_signal_term?trendSettings.macd_signal_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_signal_term" className="form-control" />
                              </Col>
                            </FormGroup>  
                              </> 
                              : trendSettings.trend_method=="supertrend"? <>
                               <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Interval <i className="bx bx-info-circle" id ="TFBSSMAINTERVAL" />
                                    <UncontrolledTooltip placement="top" target="TFBSSMAINTERVAL"> 
                                        {ToolTipData.supertrend.interval}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <select name="st_time_frame" required label="st_time_frame" className="form-select" value={trendSettings.st_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                <option value='1m'>1 minute</option>
                                <option value='5m'>5 minutes</option>
                                <option value='15m'>15 minutes</option>
                                <option value='30m'>30 minutes</option>
                                <option value='1h'>1 hour</option>
                                <option value='2h'>2 hours</option>
                                <option value='4h'>4 hours</option>
                                <option value='1d'>1 day</option>
                                <option value='1w'>1 week</option>
                                {/*<option value='1M'>1 month</option>*/}
                              </select>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              ATR length <i className="bx bx-info-circle" id ="TFBSATRL" />
                                    <UncontrolledTooltip placement="top" target="TFBSATRL"> 
                                        {ToolTipData.supertrend.atr_length}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.st_atr_length?trendSettings.st_atr_length:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_atr_length" className="form-control" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Factor <i className="bx bx-info-circle" id ="TFBSFACTOR" />
                                    <UncontrolledTooltip placement="top" target="TFBSFACTOR"> 
                                        {ToolTipData.supertrend.factor}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required min={1} step={0.1} value={trendSettings.st_factor?trendSettings.st_factor:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_factor" className="form-control" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Minimum supertrend distance <i className="bx bx-info-circle" id ="TFBSMINSD" />
                                    <UncontrolledTooltip placement="top" target="TFBSMINSD"> 
                                        {ToolTipData.supertrend.minimum_supertrend_distance}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required min={1} step={1} value={trendSettings.st_min_distance?trendSettings.st_min_distance:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_min_distance" className="form-control" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                              Maximum supertrend distance <i className="bx bx-info-circle" id ="TFBSMAXSD" />
                                    <UncontrolledTooltip placement="top" target="TFBSMAXSD"> 
                                        {ToolTipData.supertrend.maximum_supertrend_distance}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="9">                              
                              <input type="number" onWheel={handleWheel}  inputMode="numeric"  required min={1} step={1} value={trendSettings.st_max_distance?trendSettings.st_max_distance:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_max_distance" className="form-control" />
                              </Col>
                            </FormGroup>
                                                         
                              </> : '' }
                              {/* <span><a href={trendViewURL} target='_blank'  rel="noreferrer">Click Here To Check Trend</a></span> */}
                              
                     
                            </Row>
                                     </>  : '' }  
                            <div className="text-sm-end">
                                <Button className="btn-label w-md" color="success" type="submit" disabled={form6_loader.loading}>
                                  {form6_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                  <i className="bx bx-smile label-icon"></i> Submit
                                </Button>
                                </div>
                            </form>
                      
                        </div>
                      </TabPane>
                      <TabPane tabId="3">
                        <div>
                          <CardTitle>Diagonals Bot Settings</CardTitle>
                          <form key={3} onSubmit={handleSubmit3(diagonalSettingSubmit)}>
                          <Row>                                                        
                              <Col lg="3">
                                <FormGroup className=" mt-4 mb-0">
                                  <Label htmlFor="expirydateInput">
                                    Enable Diagonal Strategy
                                  </Label>{" "}<i className="bx bx-info-circle" id ="DBSEDS" />
                                    <UncontrolledTooltip placement="top" target="DBSEDS"> 
                                        {ToolTipData.admin_enable_diagonal_strategy} 
                                    </UncontrolledTooltip>
                                  <Col md="2">
                                    <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"                                      
                                    onChange={(e) => setDiagonalbot(e)}
                                    checked={diagonalbot} 
                                  />
                                  </Col>
                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="text-sm-end">
                                <Button className="btn-label w-md" color="success" type="submit" disabled={form3_loader.loading}>
                                  {form3_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                  <i className="bx bx-smile label-icon"></i> Submit
                                </Button>
                              </div>
                          </form>
                        </div>
                      </TabPane>
                      <TabPane
                        tabId="4"
                        id="v-pills-payment"
                        role="tabpanel"
                        aria-labelledby="v-pills-payment-tab"
                      >
                        <div>
                          <CardTitle>Strangles Bot Settings</CardTitle>
                          <p className="card-title-desc"> You can also change these settings while starting bot.</p>
                          <form key={2} onSubmit={handleSubmit2(botSettingSubmit)}>
                            <Row>
                            <Col lg="3">
                              <FormGroup className="mt-4 mb-0">
                                <Label htmlFor="cvvcodeInput">
                                  Enable Strangle Strategy
                                </Label>{" "}<i className="bx bx-info-circle" id ="Enable_Strangle_Strategy" />
                                    <UncontrolledTooltip placement="top" target="Enable_Strangle_Strategy"> 
                                        {ToolTipData.admin_enable_strangle_strategy}
                                    </UncontrolledTooltip>
                                <Col md="2">
                                  <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2"                                      
                                  onChange={(e) => setStranglebot(e)}
                                  checked={stranglebot} 
                                />
                                </Col>
                              </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Index %
                                  </Label>{" "}<i className="bx bx-info-circle" id ="index" />
                                    <UncontrolledTooltip placement="top" target="index"> 
                                        {ToolTipData.index_percentage}
                                    </UncontrolledTooltip>
                                  <Slider value={indexPercentage?indexPercentage:0} min={0} max={100} step={0.5} onChange={value => {setIndexPercentage(value)}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={indexPercentage ? indexPercentage:''} onChange={(e) => { setIndexPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setIndexPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="index_percentage" className="form-control" />
                                </FormGroup>
                              </Col>
                              </Row>
                              <Row>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Market Stop Loss Percentage
                                  {"  "}
                                  <i className="bx bx-info-circle" id ="StopLoss" /></Label>
                                    <UncontrolledTooltip placement="top" target="StopLoss">
                                        {ToolTipData.stop_loss}
                                    </UncontrolledTooltip>
                                  <Slider value={stopLossPercentage?stopLossPercentage:0} min={0} max={100} step={0.5} onChange={value => {setStopLossPercentage(value)}} />
                                  <input type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={stopLossPercentage?stopLossPercentage:''} onChange={(e) => { setStopLossPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setStopLossPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="stop_loss_percentage" className="form-control" />
                                </FormGroup>
                                {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="combined_sl"
                                checked={combinedSl}
                                onChange={(event) => setCombinedSl(event.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="combined_sl">
                                Combined Stop Loss
                                </label>{" "}<i className="bx bx-info-circle" id ="Stop_loss_combined" />
                                                                                                <UncontrolledTooltip placement="top" target="Stop_loss_combined"> 
                                                                                                    {ToolTipData.stop_loss_combined}
                                                                                                </UncontrolledTooltip>
                                </div> */}
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Index Price Range % for Stop Loss
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="pricerange" />
                                    <UncontrolledTooltip placement="top" target="pricerange">
                                        {ToolTipData.index_price_range}
                                    </UncontrolledTooltip>
                                  <Slider value={stopLossIndexPercentage?stopLossIndexPercentage:0}  max={100} onChange={value => {setStopLossIndexPercentage(value)}} />
                                  <input type="number" onWheel={handleWheel}  inputMode="numeric"   max={100}  value={stopLossIndexPercentage?stopLossIndexPercentage:''} onChange={(e) => { setStopLossIndexPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setStopLossIndexPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="stop_loss_index_percentage" className="form-control" />
                                </FormGroup>
                              </Col>
                              </Row>
                              <Row>
                              <Col lg="6">
                              <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Trailing Take Profit  %
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i>
                                    <Slider value={strangleMinTakeProfit?strangleMinTakeProfit:0} min={0} max={100} step={0.5} onChange={value => {setStrangleMinTakeProfit(value)}} />
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={strangleMinTakeProfit ? strangleMinTakeProfit:''} onChange={(e) => { setStrangleMinTakeProfit(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setStrangleMinTakeProfit(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="strangle_min_take_profit_percentage" className="form-control" />
                                  </FormGroup>
                                  <div className="form-check form-checkbox-outline form-check-success mt-3">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="StarangleMin"
                                    checked={SlTriggerStrangles}
                                    onChange={(event) => setSlTriggerStrangles(event.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="StarangleMin">
                                    Activate trailing take profit when stop loss triggered on one leg
                                    </label>{" "}
                                    {/* <i className="bx bx-info-circle" id ="SSLTMTP" />
                                    <UncontrolledTooltip placement="top" target="SSLTMTP"> 
                                        {ToolTipData.stop_loss_trigger_market_take_profit}
                                    </UncontrolledTooltip> */}
                                </div>
                              </Col>
                              <Col lg="6">
                              <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Trailing Stop Price %
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i>
                                    <Slider value={strangleTrailTakeProfit?strangleTrailTakeProfit:0} min={0} max={100} step={0.5} onChange={value => {setStrangleTrailTakeProfit(value)}} />
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={strangleTrailTakeProfit ? strangleTrailTakeProfit:''} onChange={(e) => { setStrangleTrailTakeProfit(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setStrangleTrailTakeProfit(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="strangle_trailing_take_profit_percentage" className="form-control" />
                                  </FormGroup>
                                  {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="StarangleTrailling"
                                    checked={TraillingStrangles}
                                    onChange={(event) => setTraillingStrangles(event.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="StarangleTrailling">
                                    Combined Profit
                                    </label>{" "}
                                </div>  */}
                              </Col>
                             </Row>  
                              <Row>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Take Profit %
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="profit" />
                                    <UncontrolledTooltip placement="top" target="profit">
                                        {ToolTipData.take_profit}
                                    </UncontrolledTooltip>
                                  <Slider value={takeProfitPercentage?takeProfitPercentage:0} min={0} max={100} step={0.5} onChange={value => {setTakeProfitPercentage(value)}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={takeProfitPercentage?takeProfitPercentage:''} onChange={(e) => { setTakeProfitPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setTakeProfitPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="take_profit_percentage" className="form-control" />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Quantity
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="quantity" />
                                    <UncontrolledTooltip placement="top" target="quantity">
                                        {ToolTipData.quantity}
                                    </UncontrolledTooltip>
                                  <Slider value={quantity?quantity:1} min={1} max={1000} onChange={value => {setQuantity(value)}} />
                                  <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={1000} value={quantity ? quantity:''} onChange={(e) => { setQuantity(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setQuantity(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="quantity" className="form-control" />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-combined-sl">
                                  Combined Stop Loss %
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="combined_sl_trigger" />
                                    <UncontrolledTooltip placement="top" target="combined_sl_trigger">
                                        {ToolTipData.stop_loss_combined}
                                    </UncontrolledTooltip>
                                  <Slider value={combinedSl?combinedSl:0} min={0} max={100} onChange={value => {setCombinedSl(value)}} />
                                  <input  type="number" onWheel={handleWheel}   max={100} step={0.5} value={combinedSl} onChange={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedSl(newValue);
                                }} onBlur={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedSl(newValue);
                                }} name="combined_sl_percentage" className="form-control" />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-combined-sl">
                                  Combined Trailing Profit %
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="combined_trail_pro_trigger" />
                                  <UncontrolledTooltip placement="top" target="combined_trail_pro_trigger">
                                      {ToolTipData.combined_trailing_profit_percentage}
                                  </UncontrolledTooltip>
                                  <Slider value={combinedTrailProfitPercentage?combinedTrailProfitPercentage:0} min={0} max={100} onChange={value => {setCombinedTrailProfitPercentage(value)}} />
                                  <input  type="number" onWheel={handleWheel}   max={100} step={0.5} value={combinedTrailProfitPercentage} onChange={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedTrailProfitPercentage(newValue);
                                }} onBlur={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedTrailProfitPercentage(newValue);
                                }} name="combined_trailing_profit_percentage" className="form-control" />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-combined-sl">
                                  Combined Trailing Stop Loss %
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="combined_trail_stop_trigger" />
                                    <UncontrolledTooltip placement="top" target="combined_trail_stop_trigger">
                                        {ToolTipData.combined_trailing_stop_price_percentage}
                                    </UncontrolledTooltip>
                                  <Slider value={combinedTrailSl?combinedTrailSl:0} min={0} max={100} onChange={value => {setCombinedTrailSl(value)}} />
                                  <input  type="number" onWheel={handleWheel}   max={100} step={0.5} value={combinedTrailSl} onChange={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedTrailSl(newValue);
                                }} onBlur={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedTrailSl(newValue);
                                }} name="combined_trailing_stop_price_percentage" className="form-control" />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-combined-sl">
                                  Combined Take Profit %
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="combined_take_profit_trigger" />
                                    <UncontrolledTooltip placement="top" target="combined_take_profit_trigger">
                                        {ToolTipData.combined_take_profit_percentage}
                                    </UncontrolledTooltip>
                                  <Slider value={combinedProfitPercentage?combinedProfitPercentage:0} min={0} max={100} onChange={value => {setCombinedProfitPercentage(value)}} />
                                  <input  type="number" onWheel={handleWheel}   max={100} step={0.5} value={combinedProfitPercentage} onChange={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedProfitPercentage(newValue);
                                }} onBlur={(e) => { 
                                  const value = e.target.value;
                                  let newValue = 100; // Default value
                                  if (value === null || value === '' || value < 0) {
                                    newValue = '';
                                  } else if (value <= 100) {
                                    newValue = value;
                                  }
                                  setCombinedProfitPercentage(newValue);
                                }} name="combined_sl_percentage" className="form-control" />
                                </FormGroup>
                              </Col>

                              <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Waiting time to fill Qty (H : M)
                                  </Label> {" "}      
                                  <i className="bx bx-info-circle" id ="qty" />
                                    <UncontrolledTooltip placement="top" target="qty">
                                        {ToolTipData.waiting_time_to_fill_qty}
                                    </UncontrolledTooltip>                                  
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    onChange={(e) => setQtyTime(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                    value={qtyTime}
                                    options={{                                      
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "H:i",
                                      time_24hr: true,
                                    }}
                                  />
                                </FormGroup>
                                <h5 className="mt-5 mb-3 font-size-15">
                                  Re-entry Settings
                                </h5>
                                <div className="p-4 border">
                                  <Row>
                              <Col lg="2">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Enable Re-Entry
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="reentry_enabledreentry_enabled" />
                                    <UncontrolledTooltip placement="top" target="reentry_enabledreentry_enabled">
                                        {ToolTipData.reentry_enabled}
                                    </UncontrolledTooltip>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                 </FormGroup>
                              </Col>
                              <Col md="4">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className=" mt-4 mb-sm-8 mb-0"                                      
                                      onChange={(e) => setreentry_enabled(e)} 
                                      checked={reentry_enabled}
                                    />
                              </Col>
                                  <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Waiting time for Re-Entry (H : M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="DHINTERVALSTRANGLESwait_time_reentry" />
                                    <UncontrolledTooltip placement="top" target="DHINTERVALSTRANGLESwait_time_reentry"> 
                                        {ToolTipData.wait_time_reentry }
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'strangleswait_time_reentry')}
                                          value={toHoursAndMinutes(strangleswait_time_reentry)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  </Row>
                                  <Row><Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Time to Close Orders Before Expiry (H : M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry" />
                                    <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry"> 
                                        {ToolTipData.time_to_close_orders_before_expiry }
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry')}
                                          value={toHoursAndMinutes(time_to_close_orders_before_expiry)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            maxTime: "12:00"
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  </Row>
                                  </div>
                                  {!reentry_enabled && <>
                                <h5 className="mt-5 mb-3 font-size-15">
                                  Roll Settings
                                </h5>
                                <div className="p-2 border">
                                  <Row>
                                    <Col lg="2">
                                    <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Enable Roll
                                      </Label>{" "}
                                      <i className="bx bx-info-circle" id ="enableroll" />
                                        <UncontrolledTooltip placement="top" target="enableroll">
                                            {ToolTipData.roll_enable}
                                        </UncontrolledTooltip>
                                        <Col md="2">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"                                      
                                          onChange={(e) => setRollStatus(e)} 
                                          checked={rollStatus}
                                        />
                                        </Col>
                                    </FormGroup>
                                  </Col>
                                  {rollStatus ? 
                                    <>
                                  <Col lg="5">
                                  <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        When to Roll? (H : M)
                                      </Label>{" "}   
                                      <i className="bx bx-info-circle" id ="roll" />
                                        <UncontrolledTooltip placement="top" target="roll">
                                            {ToolTipData.roll_wait_time}
                                        </UncontrolledTooltip>                                   
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        onChange={(e) => setRollTime(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                        value={rollTime}
                                        options={{                                          
                                          enableTime: true,
                                          noCalendar: true,
                                          dateFormat: "H:i",
                                          time_24hr: true,
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="5">
                                    <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Distance from index
                                      </Label> {" "}      
                                      <i className="bx bx-info-circle" id ="dis_index" />
                                        <UncontrolledTooltip placement="top" target="dis_index">
                                            {ToolTipData.roll_index_min_distance}
                                        </UncontrolledTooltip>                                 
                                      <input type="number" onWheel={handleWheel}  inputMode="numeric"  onChange={(e) => setIndexDistance(e.target.value)} onBlur={(e) => setIndexDistance(e.target.value)} name="index_distance" {...register2("index_distance", { required: true,pattern: /^(0|[1-9]\d*)(\.\d+)?$/ })} className="form-control" />
                                      {errors2.index_distance && <small className="text-danger">Please provide a valid distance from index</small>}
                                    </FormGroup>
                                  </Col>
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Premium Roll %
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="pre_roll" />
                                        <UncontrolledTooltip placement="top" target="pre_roll">
                                            {ToolTipData.roll_premium_min_percentage}
                                        </UncontrolledTooltip> 
                                    <Slider value={rollPercentage?rollPercentage:0} min={1} max={100} step={0.5} onChange={value => {setRollPercentage(value)}} />
                                    <input type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={rollPercentage?rollPercentage:''} onChange={(e) => { setRollPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setRollPercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="roll_percentage" className="form-control" />
                                  </FormGroup>
                                  </>
                                  :''}
                                </Row>
                              </div>
                              </>}
                              
                              <Col lg="3">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Universal stop
                                  </Label>{" "}
                                    <i className="bx bx-info-circle" id ="Universal" />
                                        <UncontrolledTooltip placement="top" target="Universal">
                                            {ToolTipData.universal_stop}
                                        </UncontrolledTooltip> 
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setUniversalStop(e)} 
                                      checked={universalStop}
                                    />
                                    </Col>
                                </FormGroup>
                              </Col>
                              <h5 className="mt-5 mb-3 font-size-15">
                                Automatic Bot Settings
                                </h5>
                              <div className="p-2 border">
                                <Row>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                    Enable Automatic Mode for Strangles
                                    </Label>{" "}
                                  <i className="bx bx-info-circle" id ="ABSEAMFS" />
                                    <UncontrolledTooltip placement="top" target="ABSEAMFS">
                                        {ToolTipData.admin_enable_automatic_strangle}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setAutomaticStranglebot(e)}
                                      checked={automaticStranglebot}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>                        
                                {automaticStranglebot ? 
                                <>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                      Market Range %  <i className="bx bx-info-circle" id ="ABSMR" />
                                    <UncontrolledTooltip placement="top" target="ABSMR">
                                        {ToolTipData.index_percentage}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">                              
                                  <Slider value={marketRangePercentage?marketRangePercentage:1} min={1} max={100} step={0.5} onChange={value => {setMarketRangePercentage(value)}} />
                                      <input type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100} step={0.5} value={marketRangePercentage ? marketRangePercentage:''} onChange={(e) => { setMarketRangePercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) ) } } onBlur={(e) => setMarketRangePercentage(Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) )} name="market_range_percentage" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                      Minimum premium <i className="bx bx-info-circle" id ="Minper" />
                                    <UncontrolledTooltip placement="top" target="Minper">
                                        {ToolTipData.admin_automatic_bot_minimum_premium}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Col md="9">                              
                                      <input type="number" onWheel={handleWheel}  inputMode="numeric"   value={minimumPremium ? minimumPremium:''}  name="minimum_premium" className="form-control" onChange={e => {setMinimumPremium(Number(e.target.value))}} />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                      Maximum Spread % <i className="bx bx-info-circle" id ="Maxspread" />
                                    <UncontrolledTooltip placement="top" target="Maxspread">
                                        {ToolTipData.admin_automatic_bot_maximum_spread}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Col md="9">                              
                                      <input type="number" onWheel={handleWheel}  inputMode="numeric"   value={spreadPercentage ? spreadPercentage:''}  name="spread_percentage" className="form-control" onChange={e => {setSpreadPercentage(Number(e.target.value))}} />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Strangle price difference % <i className="bx bx-info-circle" id ="ABSSPD" />
                                    <UncontrolledTooltip placement="top" target="ABSSPD">
                                        {ToolTipData.admin_automatic_strangle_price}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Col md="9">                              
                                  <Slider orientation="horizontal"  value={stranglePricePercentage?stranglePricePercentage:0}  onChange={value => {setStranglePricePercentage(value)}}  />
                                  <input type="number" onWheel={handleWheel}  inputMode="decimal"   min="0" max ="100" step="0.0001" value={stranglePricePercentage ? stranglePricePercentage:0}  onChange={(e) => { setStranglePricePercentage(Number(e.target.value) ) } }  name="strangle_price_difference_percentage" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mt-4 mb-0" row>
                                          <Label htmlFor="formrow-firstname-Input"  md="3"  className="col-form-label">
                                          Time interval to start checking New Expiry (H : M) <i className="bx bx-info-circle" id ="ABSTI" />
                                    <UncontrolledTooltip placement="top" target="ABSTI">
                                        {ToolTipData.admin_automatic_bot_waiting_time}
                                    </UncontrolledTooltip>
                                          </Label>  
                                          <Col md="9">                                     
                                          <Flatpickr
                                            className="form-control d-block"
                                            placeholder="Select time"
                                            onChange={(e) => setExpiryTimeInterval(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                            value={expiryTimeInterval}
                                            options={{                                          
                                              enableTime: true,
                                              noCalendar: true,
                                              dateFormat: "H:i",
                                              time_24hr: true,
                                            }}
                                          />
                                          </Col>
                                        </FormGroup>
                                </>
                                :''}

                                {/* <div className="text-sm-end">
                                  <Button className="btn-label w-md" color="primary" onClick={autobotSettingSubmit}>
                                    {form7_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                    <i className="bx bx-smile label-icon"></i> Submit
                                  </Button>
                                </div>  */}
                              </Row>
                            </div>

                              <div className="text-sm-end mt-4">
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form2_loader.loading}>
                              {form2_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                            </div>
                            </Row>
                          </form>                                                    
                        </div>
                      </TabPane>  
                      <TabPane tabId="5">
                        <div>
                          <CardTitle>Telegram Settings</CardTitle>
                          <p className="card-title-desc">
                          If you want to update the telegram notification content and token details please enable the status.
                          </p>
                    
                          <form key={4} onSubmit={handleSubmit4(telegramSettingSubmit)}>
                            <Row  className="p-4 border mb-3">
                                <Col lg="2">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-status-Input">
                                      Status
                                    </Label>{" "}<i className="bx bx-info-circle" id ="STATUS" />
                                    <UncontrolledTooltip placement="top" target="STATUS">
                                        {ToolTipData.admin_telegram_enable}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setTelegramStatus(e)}
                                      checked={telegramStatus}
                                    />
                                    </Col>                                  
                                  </FormGroup>
                                </Col>
                                <Col lg="5">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-open_order-Input">
                                      Open Order
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="OOMSG" />
                                    <UncontrolledTooltip placement="top" target="OOMSG">
                                        {ToolTipData.open_order_msg}
                                    </UncontrolledTooltip>                                    
                                    <textarea name="sell_order_msg" className="form-control" {...register4("sell_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                    {errors4.sell_order_msg && <small className="text-danger">Please provide a sell order msg</small>}
                                  </FormGroup>
                                </Col>
                                <Col lg="5">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-position_order-Input">
                                      Open Position
                                    </Label> {" "}
                                    <i className="bx bx-info-circle" id ="OPMSG" />
                                    <UncontrolledTooltip placement="top" target="OPMSG">
                                        {ToolTipData.open_position_msg}
                                    </UncontrolledTooltip>                                   
                                    <textarea name="position_order_msg" className="form-control" {...register4("position_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                    {errors4.position_order_msg && <small className="text-danger">Please provide a position order msg</small>}
                                  </FormGroup>
                                </Col>
                                {/* <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="buy_order_msg">
                                      Buy Order
                                    </Label>
                                      <textarea name="buy_order_msg" className="form-control" {...register4("buy_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.buy_order_msg && <small className="text-danger">Please provide a buy order msg</small>}                                    
                                  </FormGroup>
                                </Col> */}
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="filled_order_msg">
                                      Close Position
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="CPMSG" />
                                    <UncontrolledTooltip placement="top" target="CPMSG">
                                        {ToolTipData.close_position_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="filled_order_msg" className="form-control" {...register4("filled_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.filled_order_msg && <small className="text-danger">Please provide a close position msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="trigger_order_msg">
                                      Trigger Order in SL
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TOSLMSG" />
                                    <UncontrolledTooltip placement="top" target="TOSLMSG">
                                        {ToolTipData.trigger_order_in_sl_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="trigger_order_msg" className="form-control" {...register4("trigger_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.trigger_order_msg && <small className="text-danger">Please provide a trigger order msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="sl_order_msg">
                                      SL Order
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="SLOMSG" />
                                    <UncontrolledTooltip placement="top" target="SLOMSG">
                                        {ToolTipData.sl_order_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="sl_order_msg" className="form-control" {...register4("sl_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.sl_order_msg && <small className="text-danger">Please provide a SL order msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="roll_order_msg">
                                      Roll
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="ROLLMSG" />
                                    <UncontrolledTooltip placement="top" target="ROLLMSG">
                                        {ToolTipData.roll_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="roll_order_msg" className="form-control" {...register4("roll_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.roll_order_msg && <small className="text-danger">Please provide a roll order msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="profit_order_msg">
                                      Take Profit
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TPMSG" />
                                    <UncontrolledTooltip placement="top" target="TPMSG">
                                        {ToolTipData.take_profit_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="profit_order_msg" className="form-control" {...register4("profit_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.profit_order_msg && <small className="text-danger">Please provide a take profit msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="universel_order_msg">
                                      Universal Close
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="UCMSG" />
                                    <UncontrolledTooltip placement="top" target="UCMSG">
                                        {ToolTipData.universal_close_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="universel_order_msg" className="form-control" {...register4("universel_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.universel_order_msg && <small className="text-danger">Please provide a universel order msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="cancelled_order_msg">
                                      Cancel Order
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="COMSG" />
                                    <UncontrolledTooltip placement="top" target="COMSG">
                                        {ToolTipData.cancel_order_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="cancelled_order_msg" className="form-control" {...register4("cancelled_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.cancelled_order_msg && <small className="text-danger">Please provide a cancelled order msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="mismatch_order_msg">
                                      Mismatch Order
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="MOMSG" />
                                    <UncontrolledTooltip placement="top" target="MOMSG">
                                        {ToolTipData.mismatch_order_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="mismatch_order_msg" className="form-control" {...register4("mismatch_order_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.mismatch_order_msg && <small className="text-danger">Please provide a Mismatch order msg</small>}                                    
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="trend_signal">
                                      Trend Signal
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TSMSG" />
                                    <UncontrolledTooltip placement="top" target="TSMSG">
                                        {ToolTipData.trend_signal_msg}
                                    </UncontrolledTooltip>
                                      <textarea name="trend_signal_msg" className="form-control" {...register4("trend_signal_msg", { required: telegramStatus ? true:false})} disabled={telegramStatus?false:true} />
                                      {errors4.trend_signal_msg && <small className="text-danger">Please provide a trend signal msg</small>}
                                  </FormGroup>
                                </Col>
                              </Row> 

                              <Row  className="p-4 border mb-3">
                                <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Token <i className="bx bx-info-circle" onClick={() => setVisible(!visible)}></i>
                                    </Label>
                                    <input
                                      type="text"
                                      name="admin_tele_token"
                                      className="form-control"                                      
                                      placeholder="Enter your token"
                                      {...register4("admin_tele_token", { required: false})}
                                      //disabled={telegramStatus?false:true}
                                    />
                                    {/* {errors4.admin_tele_token && <small className="text-danger">Please provide a token</small>} */}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Your ID <i className="bx bx-info-circle" onClick={() => setVisibleID(!visibleID)}></i> OR Chat ID <i className="bx bx-info-circle" onClick={() => setVisibleChatID(!visibleChatID)}></i>
                                    </Label>
                                    <input
                                      type="text"
                                      name="admin_tele_chatid"
                                      className="form-control"                                      
                                      placeholder="Enter your ID"
                                      {...register4("admin_tele_chatid", { required: false})}
                                      //disabled={telegramStatus?false:true}
                                    />
                                    <span>Note : If you want to update chat id please add - symbol (Ex : -123456)</span>
                                    {/* <br></br>{errors4.admin_tele_chatid && <small className="text-danger">Please provide a valid your id</small>} */}
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Automatic Bot Orders Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TSMABOM" />
                                    <UncontrolledTooltip placement="top" target="TSMABOM">
                                        {ToolTipData.automatic_bot_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setAdminOrderStatus(e)} 
                                      checked={adminOrderStatus}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Admin Bot Orders Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TSMSABOM" />
                                    <UncontrolledTooltip placement="top" target="TSMSABOM">
                                        {ToolTipData.admin_bot_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onChange={(e) => setAdminBotMsg(e)}
                                      checked={adminBotMsg}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      User Profit Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TSMSUPM" />
                                    <UncontrolledTooltip placement="top" target="TSMSUPM">
                                        {ToolTipData.user_profit_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onChange={(e) => setUserProfitMsgStatus(e)}
                                      checked={userProfitMsgStatus}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                {userProfitMsgStatus ? 
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      User Profit Msg Send Time
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TSMSUPMST" />
                                    <UncontrolledTooltip placement="top" target="TSMSUPMST">
                                        {ToolTipData.user_profit_msg_interval}
                                    </UncontrolledTooltip>
                                    <select name="user_profit_msg_time" required label="user_profit_msg_time" className="form-select" value={userProfitMsgTime} onChange={(e) => setUserProfitMsgTime(e.target.value)} >
                                    <option value='5m'>5 minutes</option>
                                    <option value='10m'>10 minutes</option>
                                    <option value='15m'>15 minutes</option>
                                    <option value='30m'>30 minutes</option>
                                    <option value='1h'>1 hour</option>
                                    <option value='2h'>2 hours</option>
                                    <option value='3h'>3 hours</option>
                                    <option value='4h'>4 hours</option>
                                    <option value='5h'>5 hours</option>                                    
                                  </select>                                    
                                  </FormGroup>
                                </Col>
                                :''}
                              </Row> 
                              <div className="text-sm-end">
                              <Button className="btn-label w-md" color="success" type="submit" disabled={form4_loader.loading}>
                                {form4_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                <i className="bx bx-smile label-icon"></i> Submit
                              </Button>
                            </div>                       
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="7">
                        <div>
                          <CardTitle>Server Settings</CardTitle>
                          <div   className="border-bottom">
                          <Col lg="4">
                          <p className="form-label form-label">
                            Server IP address 
                          </p> 
                            <div className="input-group border-bottom mb-4">
                           
                                    {/* <Label htmlFor="formrow-firstname-Input">
                                      Server IP address 
                                    </Label> */}
                                    <input
                                      type="text"
                                      name="server_ip"
                                      className="form-control"                                      
                                      placeholder="Enter Server IP address"
                                      aria-label="Server IP address"
                                      value={serverIP}
                                      onChange={(e) => setServerIP(e.target.value)}
                                    /> 
                                    <button className="btn btn-success" type="button" id="inputGroupFileAddon04" onClick={(e)=>saveIP()}>Save</button>
                                    {/* <br></br>{errors4.admin_tele_chatid && <small className="text-danger">Please provide a valid your id</small>} */}
                                  </div></Col>
                                  </div>
                          <p className="form-label form-label mt-3">
                          To restart the server please click the restart button.
                          </p>
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form8_loader.loading} onClick={(e) => restartServer()}>
                              {form8_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Restart
                            </Button>
                        </div>
                      </TabPane>
                      {/* <TabPane tabId="8">
                        <div>
                          <CardTitle>CCXT Settings</CardTitle>
                          <Col lg="4">
                            <FormGroup className=" mt-4 mb-0">
                                <Label htmlFor="expirydateInput">
                                Enable Spot Trading
                                </Label>{" "}
                            
                                <Col md="2">
                                  <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2"                                      
                                  onChange={(e) => setCCXTspottrade(e)}
                                  checked={CCXTspottrade}
                                />
                                </Col>
                              </FormGroup>
                            </Col>
                          <Col lg="4">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Interval for SuperTrend Trend Signal Confirmation (H : M)
                                        </Label> {" "}
                              <i className="bx bx-info-circle" id ="CCXTTIME" />
                                    <UncontrolledTooltip placement="top" target="CCXTTIME"> 
                                        {ToolTipData.CCXT.Time}
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForPrice"
                                          onChange={(e) => handleCCXTTime(e)}
                                          value={toHoursAndMinutes(CCXTTrendTime)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  <Button className="btn-label w-md float-end" color="success" type="submit" disabled={form9_loader.loading} onClick={(e) => CCXTSettings()}>
                              {form9_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                        </div> 
                      </TabPane> */}
                    </TabContent>
                  </CardBody>
                </Card>                
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal
        isOpen={visible}
        toggle={() => {
        setVisible(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          How to Create Token in Telegram
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisible(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ teleMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
          setVisible(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleID}
        toggle={() => {
        setVisibleID(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          How to Create Your ID in Telegram
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleID(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ teleIDMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleID(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>

      <Modal
        isOpen={visibleChatID}
        toggle={() => {
        setVisibleChatID(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          How to Create Chat ID in Telegram
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleChatID(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ teleChatIDMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleChatID(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleMin}
        toggle={() => {
        setVisibleMin(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
        setVisibleTailling(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleMidPrize}
        toggle={() => {
        setVisibleMidPrize(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MidPrizeMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Settings
