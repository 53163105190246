import PropTypes from "prop-types";
import React,{useState,useEffect} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,  
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import WelcomeComp from "./WelcomeComp";

//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import api from '../../helpers/api_helper';
import firebase from "firebase/compat/app";
const Dashboard = props => {
firebase.auth();
const firestoreDb = firebase.firestore();
const currentUser = JSON.parse(localStorage.getItem('user'));
let uid = currentUser.uid;
const [strangleOpenOrder, setStrangleOpenOrder] = useState();
const [stranglePositionOrder, setStranglePositionOrder] = useState();
const [diagonalOpenOrder, setDiagonalOpenOrder] = useState();
const [diagonalPositionOrder, setDiagonalPositionOrder] = useState();
const [userBalance,setUserBalance] = useState();
const [straddleOpenOrder, setStraddleOpenOrder] = useState();
const [straddlePositionOrder, setStraddlePositionOrder] = useState();

const getUserBalance = async()=>{
  await api({ contentType: true, auth: true }).get('/abotex/delta/get-balance').then(res => {
    setUserBalance(res.data.response.balance);
  }).catch(err => {
    setUserBalance('');
    throw err;
  })
}
const [todaydata, settodaydata] = useState(false)
const get_latest_getOrdersDetails = async()=>{
  
  var graph_created_at = localStorage.getItem('graph_created_at')
  let converted_date = new Date();
  let converted_start_date = new Date(graph_created_at);
  let converted_end_date2 = new Date(converted_date);
  const strangle_order = await firestoreDb.collectionGroup("strangle_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'open').where('status', '==', 'closed').where('created_at', '>=', converted_start_date.toISOString()).where('created_at', '<=', converted_end_date2.toISOString()).where('direction','==','sell').get().then(stra_order => { 
    if (stra_order.size > 0){
      settodaydata(true)
    }
   })
   const diagonal_order = await firestoreDb.collectionGroup("diagonal_orders").where('created_at', '>=', converted_start_date.toISOString()).where('created_at', '<=', converted_end_date2.toISOString()).where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'open').where('status', '==', 'closed').get().then(dia_order => {
    if (dia_order.size > 0){
      settodaydata(true)
    }
   })
   const straddle_order = await firestoreDb.collectionGroup("straddle_orders").where('created_at', '>=', converted_start_date.toISOString()).where('created_at', '<=', converted_end_date2.toISOString()).where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'open').where('status', '==', 'closed').where('bot_status','==','active').get().then(straddle_order => {
    if (straddle_order.size > 0){
      settodaydata(true)
    }
  })

}
let incount = 0
const getOrdersDetails = async()=>{
  var data = '{'
  const strangle_open_order = await firestoreDb.collectionGroup("strangle_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'open').get().then(stra_open_order => {        
    data = data + '"stra_open_order"'+':"'+stra_open_order.size +'"'
    setStrangleOpenOrder(stra_open_order.size);
  });
  const strangle_position_order = await firestoreDb.collectionGroup("strangle_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'closed').where('direction','==','sell').get().then(stra_position_order => {
    data = data + ', "stra_position_order'+'":"'+stra_position_order.size+'"'
    setStranglePositionOrder(stra_position_order.size);
  });
  const diagonal_open_order = await firestoreDb.collectionGroup("diagonal_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'open').get().then(dia_open_order => {
    setDiagonalOpenOrder(dia_open_order.size);
    data = data + ', "dia_open_order'+'":"'+dia_open_order.size+'"'
  });
  const diagonal_position_order = await firestoreDb.collectionGroup("diagonal_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'closed').get().then(dia_position_order => {
    setDiagonalPositionOrder(dia_position_order.size);
    data = data + ',"dia_position_order'+'":"'+dia_position_order.size+'"'
  });
  const straddle_open_order = await firestoreDb.collectionGroup("straddle_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'open').get().then(straddle_open_order => {        
    setStraddleOpenOrder(straddle_open_order.size);
    data = data + ',"straddle_open_order"'+':"'+straddle_open_order.size+'"'
  });
  const straddle_position_order = await firestoreDb.collectionGroup("straddle_orders").where("admin_bot", '==', true).where("admin_id", '==' ,uid).where('status', '==', 'closed').where('bot_status','==','active').get().then(straddle_position_order => {
    setStraddlePositionOrder(straddle_position_order.size);
    data = data + ',"straddle_position_order'+'":"'+straddle_position_order.size+'"'
  });
  data = data + '}'
  localStorage.setItem("orders_details",data.toString())
}

useEffect(() => {
  getUserBalance();
  get_latest_getOrdersDetails();
},[]);
useEffect(()=>{
  var createdatchange = false  
  var order_created_at = localStorage.getItem('order_created_at')
  var time_difference = new Date().getTime() - new Date(order_created_at).getTime() 
  var days_difference = time_difference / (1000 * 60 * 60 * 24);
  if (todaydata === true){
    localStorage.removeItem('orders_details')
    settodaydata(false)
  }
  if(days_difference.toFixed(0) > 0){
    localStorage.removeItem('orders_details')
  }
  if (localStorage.getItem('orders_details') === null){
    incount = incount + 1
    if (incount === 1){
      getOrdersDetails();
      createdatchange = true
    }
  }else{
    createdatchange = false
    var data = JSON.parse(localStorage.getItem('orders_details'))
        setStranglePositionOrder(data.stra_position_order);
        setDiagonalOpenOrder(data.dia_open_order);
        setDiagonalPositionOrder(data.dia_position_order);
        setStraddleOpenOrder(data.straddle_open_order);
        setStraddlePositionOrder(data.straddle_position_order);
        setStrangleOpenOrder(data.stra_open_order);
  }
  if (createdatchange === true){
    localStorage.removeItem('order_created_at')
    localStorage.setItem('order_created_at',new Date())
  }
  incount = 0
})
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Derisnap")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>            
            <Col xl="12">
              <WelcomeComp />              
            </Col>
            <Col xl="6">
            <Card style={{minHeight:'92%'}}>
                <CardBody>
                  <h4 className="card-title text-capitalize">Admin Wallet Details</h4>
                  <Row>
                    <Col lg="4">
                      <div className="border p-3 rounded mt-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-warning text-white font-size-18">
                              <i className="mdi mdi-bitcoin" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0">BTC</h5>
                        </div>

                        <Row>
                          <div className="col-lg-6">
                            <div className="mt-3">
                              <p>Wallet</p>
                              <h4 className={userBalance && userBalance.btc  && userBalance.btc.balance ? "text-warning":"text-white"}>{userBalance && userBalance.btc && userBalance.btc.balance ? parseFloat(userBalance.btc.balance).toFixed(2): 0}</h4>
                              {/* <p className="mb-0">0.00745 BTC</p> */}
                            </div>
                          </div>

                          <div className="col-lg-6 align-self-end">
                            <div className="float-end mt-3">
                            <p>Available</p>
                              <h4 className={userBalance && userBalance.btc && userBalance.btc.available_balance ? "text-warning":"text-white"}>{userBalance && userBalance.btc && userBalance.btc.available_balance  ? parseFloat(userBalance.btc.available_balance).toFixed(2): 0}</h4>
                              {/* <p className="mb-0">0.00745 BTC</p> */}
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="border p-3 rounded mt-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-success text-white font-size-18">
                              <i className="mdi mdi-alpha-t-circle" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0">USD</h5>
                        </div>

                        <Row>
                          <div className="col-lg-6">
                            <div className="mt-3">
                              <p>Wallet</p>
                              <h4 className={userBalance && userBalance.usd && userBalance.usd.balance ? "text-success" : "text-block"}>{userBalance && userBalance.usd && userBalance.usd.balance ? parseFloat(userBalance.usd.balance).toFixed(2) : 0}</h4>                          
                            </div>
                          </div>

                          <div className="col-lg-6 align-self-end">
                            <div className="float-end mt-3">
                            <p>Available</p>
                            <h4 className={userBalance && userBalance.usd && userBalance.usd.available_balance ? "text-success" : "text-block"}>{userBalance && userBalance.usd  && userBalance.usd.available_balance ? parseFloat(userBalance.usd.available_balance).toFixed(2) : 0}</h4>                              
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="border p-3 rounded mt-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary text-white font-size-18">
                              <i className="mdi mdi-ethereum" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0">ETH</h5>
                        </div>

                        <Row>
                          <div className="col-lg-6">
                            <div className="mt-3">
                              <p>Wallet</p>
                              <h4 className={userBalance && userBalance.eth && userBalance.eth.balance ? "text-primary":"text-white"}>{userBalance && userBalance.eth && userBalance.eth.balance ? parseFloat(userBalance.eth.balance).toFixed(2): 0}</h4>                              
                            </div>
                          </div>

                          <div className="col-lg-6 align-self-end">
                            <div className="float-end mt-3">
                            <p>Available</p>
                              <h4 className={userBalance && userBalance.eth && userBalance.eth.available_balance ? "text-primary":"text-white"}>{userBalance && userBalance.eth && userBalance.eth.available_balance  ? parseFloat(userBalance.eth.available_balance).toFixed(2): 0}</h4>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>                    
                  </Row>
                </CardBody>
              </Card>
              </Col>
              <Col xl="6">
              <Card style={{minHeight:'92%'}}>
                <CardBody>
                  <h4 className="card-title text-capitalize">User Order Details</h4>
                  <Row>
                  <Col lg="6">
                    <div className="border p-3 rounded mt-4">
                    <div className="d-flex align-items-center mb-3">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                          <i className="bx bx-hourglass" />
                        </span>
                      </div>
                      <h5 className="font-size-14 mb-0">Open orders</h5>
                    </div>

                    <Row>
                      <div className="col-lg-4">
                        <div className="mt-3">
                          <p>Strangles</p>
                          <h4 className={strangleOpenOrder > 0 ? "text-info":"text-white"}>{strangleOpenOrder}</h4>                              
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="mt-3">
                        <p>Diagonals</p>
                          <h4 className={diagonalOpenOrder > 0 ? "text-info":"text-white"}>{diagonalOpenOrder}</h4>                              
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="mt-3">
                        <p>Straddles</p>
                          <h4 className={straddleOpenOrder > 0 ? "text-info":"text-white"}>{straddleOpenOrder}</h4>                              
                        </div>
                      </div>
                    </Row>
                    </div>
                    </Col>
                    <Col lg="6">
                    <div className="border p-3 rounded mt-4">
                    <div className="d-flex align-items-center mb-3">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                          <i className="bx bx-check-circle" />
                        </span>
                      </div>
                      <h5 className="font-size-14 mb-0">Position Orders</h5>
                    </div>

                    <Row>
                      <div className="col-lg-4">
                        <div className="mt-3">
                          <p>Strangles</p>
                          <h4 className={stranglePositionOrder > 0 ? "text-info":"text-white"}>{stranglePositionOrder}</h4>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mt-3">
                        <p>Diagonals</p>
                          <h4 className={diagonalPositionOrder > 0 ? "text-info":"text-white"}>{diagonalPositionOrder}</h4>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mt-3">
                        <p>Straddles</p>
                          <h4 className={straddlePositionOrder > 0 ? "text-info":"text-white"}>{straddlePositionOrder}</h4>
                        </div>
                      </div>
                    </Row>
                    </div>
                    </Col>                    
                  </Row>
                </CardBody>
              </Card>
              </Col>

              <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Go to</h4>

                  <div>
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="bx bx-cog h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Settings</h5>
                              <span className="float-end" style={{marginTop:"-4px"}}>
                                <Link to="/settings" className="btn btn-primary  btn-sm"> Go to Settings <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                Update Api, Site and Telegram.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="mdi mdi-alpha-s-box h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Strangles</h5>
                              <span className="float-end" style={{marginTop:"-4px"}}>
                                <Link to="/strangles/create" className="btn btn-primary  btn-sm">Go to Strangles <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Strangles.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="mdi mdi-alpha-d-box h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Diagonals</h5>
                              <span className="float-end" style={{marginTop:"-4px"}}>
                                <Link to="/diagonals/create" className="btn btn-primary  btn-sm">Go to Diagonals <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Diagonals
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="bx bx-cube h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Straddles</h5>
                              <span className="float-end" style={{marginTop:"-4px"}}>
                                <Link to="/straddles/create" className="btn btn-primary  btn-sm">Go to Straddles <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Straddles
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
